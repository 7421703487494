import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
  ArrowForwardIcon,
  ChatBubbleInstaIcon,
  HeartIcon,
  MoreHorizIcon,
  SendIcon,
  BookmarkOutlineIcon,
} from 'images'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const InstagramPreview = ({ data, onLoaded }) => {
  const bodyRef = useRef(null)
  const [isLongText, setIsLongText] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (bodyRef) setIsLongText(bodyRef.current.scrollHeight > bodyRef.current.clientHeight)
  }, [])

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState)
  }

  if (!data) return null

  const { page_name, profile_picture_url } = data ?? {}

  return (
    <div className="instagram px-0 py-3">
      <div className="d-flex justify-content-between gap-2 px-3">
        <div className="d-flex align-items-center flex-row w-100">
          <div>
            {profile_picture_url ? (
              <img
                alt={page_name}
                className="instagram__profile_picture"
                src={profile_picture_url}
              />
            ) : (
              <div className={`instagram__icon ${data?.fb_info_loading ? 'loading' : ''}`} />
            )}
          </div>
          {page_name ? (
            <p className="instagram__title">
              <strong>{page_name}</strong>
              <span className="subtitle">Sponsored</span>
            </p>
          ) : data?.fb_info_loading ? (
            <div className="fb-error loading" />
          ) : (
            <div className="fb-error">{t('Recommendations.summary.fbPageInfoError')}</div>
          )}
        </div>
        <div>
          <MoreHorizIcon width={30} />
        </div>
      </div>
      <div>
        <div className="instagram__link">
          {data.media.type.includes('video') ? (
            <video
              className="instagram__link_media"
              style={{ aspectRatio: `${data.media.ratio < 1 ? 0 : 1}` }}
              src={data.media.url}
              autoPlay
              loop
              muted
              playsInline
              onLoad={onLoaded}
            />
          ) : (
            <LazyLoadImage
              className="instagram__link_media"
              style={{ aspectRatio: `${data.media.ratio < 1 ? 0 : 1}` }}
              src={data.media.url}
              alt={data.media.filename}
              width={data.media.width}
              height={data.media.height}
              onLoad={onLoaded}
            />
          )}
          <div className="instagram__link_order-now">
            <span>{data.call_to_action}</span>
            <span>
              <ArrowForwardIcon />
            </span>
          </div>
        </div>
        <div className="divider" />
        <div className="instagram__interactions">
          <div className="d-flex">
            <span>
              <HeartIcon />
            </span>
            <span>
              <ChatBubbleInstaIcon />
            </span>
            <span>
              <SendIcon />
            </span>
          </div>
          <BookmarkOutlineIcon height={25} />
        </div>
      </div>
      <p className="instagram__caption">
        <span ref={bodyRef} className={`${showMore ? 'text' : 'text_clipped'}`}>
          {data.body}
        </span>
        {isLongText && (
          <span className="more" onClick={toggleShowMore}>
            {showMore ? 'less' : 'more'}
          </span>
        )}
      </p>
    </div>
  )
}

InstagramPreview.propTypes = {
  data: PropTypes.object,
  onLoaded: PropTypes.func,
}

export default InstagramPreview
