import React, { useEffect, useRef, useState } from 'react'
import PropTypes, { object } from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import MediaDropzone from './MediaDropzone'
import MediaList from './MediaList'

import { useDropzone } from 'react-dropzone'
import { actions, asyncActionHandlers, fileStatus, initialState, mediaUploadReducer } from './media'
import { useReducerAsync } from 'hooks/useReducerAsync'
import PortalModal from 'components/Shared/PortalModal'
import { filesize } from 'filesize'
import { CancelIcon, LeftArrowCircleIcon, RightArrowCircleIcon } from 'images'
import { useCampaignSetupDispatch } from '../Context/CampaignSetupContext'

const MediaUpload = ({
  initialMedia,
  onChange,
  readonly,
  canAdd,
  canRemove,
  canReplace,
  maxItems = Number.MAX_VALUE,
  validationError,
  isCampaignRunning,
}) => {
  const ref = useRef(null)
  const [state, dispatch] = useReducerAsync(
    mediaUploadReducer,
    { ...initialState, media: initialMedia },
    asyncActionHandlers,
  )
  const { setHasPendingMedia } = useCampaignSetupDispatch()
  const [isMobileSize, setIsMobileSize] = useState(false)
  const [expandedImage, setExpandedImage] = useState(null)
  const { t } = useTranslation()

  const { media, fileToReplace } = state

  const canAddInternal = canAdd && media.length < maxItems
  const reachedMaxLimit = media.length >= maxItems

  useEffect(() => {
    const _media = media.filter((x) => ![fileStatus.uploading, fileStatus.new].includes(x.status))
    const _uploadingMedia = media.filter((x) =>
      [fileStatus.uploading, fileStatus.new].includes(x.status),
    )
    const hasDiff =
      _media.some((x) => initialMedia.every((m) => m.id !== x.id || m.status !== x.status)) ||
      initialMedia.some((x) => _media.every((m) => m.id !== x.id || m.status !== x.status))
    if (hasDiff) {
      const removedIdx = initialMedia.findIndex((x) => !_media.some((m) => m.id === x.id))
      onChange(media, removedIdx >= 0 ? removedIdx : null, _uploadingMedia.length > 0)
    } else if (_uploadingMedia.length > 0) {
      setHasPendingMedia(true)
    }
  }, [media, initialMedia, onChange, setHasPendingMedia])

  useEffect(() => {
    const uploadDiv = ref.current
    const handleDimensionChange = () => {
      if (uploadDiv.offsetWidth < 550) {
        setIsMobileSize(true)
      } else setIsMobileSize(false)
    }

    window.addEventListener('resize', handleDimensionChange)
    return () => window.removeEventListener('resize', handleDimensionChange)
  }, [])

  const validateFile = async (file) => {
    const errorMessages = []

    // Image File Validation
    if (file.type.startsWith('image/')) {
      // Check size (Max 30 MB)
      if (file.size > 30 * 1024 * 1024) {
        errorMessages.push(t('common.errors.fileErrors.imageSize', { fileName: file.name }))
      }

      // Check type (JPG, PNG)
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        errorMessages.push(t('common.errors.fileErrors.imageType', { fileName: file.name }))
      }

      await new Promise((resolve) => {
        const img = new Image()
        img.onload = () => {
          const { width, height } = img
          const aspectRatio = width / height
          const isverticalFormat = aspectRatio >= 0.5 && aspectRatio <= 0.575 && height > width
          // Reel,story image - resolution validation
          if (isverticalFormat && (width > 1080 || height > 1920)) {
            errorMessages.push(
              t('common.errors.fileErrors.imageResolution', { fileName: file.name }),
            )
          }
          resolve()
        }
        img.src = URL.createObjectURL(file)
      })
    }

    // Video File Validation
    if (file.type.startsWith('video/')) {
      // Check size (Max 1 GB for videos)
      if (file.size > 1024 * 1024 * 1024) {
        errorMessages.push(t('common.errors.fileErrors.videoSize', { fileName: file.name }))
      }

      // Check type (MP4, MOV)
      if (!['video/mp4', 'video/quicktime'].includes(file.type)) {
        errorMessages.push(t('common.errors.fileErrors.videoType', { fileName: file.name }))
      }

      await new Promise((resolve) => {
        const video = document.createElement('video')
        video.onloadedmetadata = () => {
          const { videoWidth, videoHeight } = video
          const aspectRatio = videoWidth / videoHeight
          const isverticalFormat =
            aspectRatio >= 0.5 && aspectRatio <= 0.575 && videoHeight > videoWidth
          // Feed validation
          if (video.duration > 240 * 60) {
            errorMessages.push(
              t('common.errors.fileErrors.videoDurationFeed', { fileName: file.name }),
            )
          }
          // Reel, Story validation
          if (isverticalFormat) {
            if (file.size > 30 * 1024 * 1024) {
              errorMessages.push(
                t('common.errors.fileErrors.reelStorySize', { fileName: file.name }),
              )
            }
            if (video.duration > 15) {
              errorMessages.push(
                t('common.errors.fileErrors.videoDurationReelStory', { fileName: file.name }),
              )
            }
            if (videoWidth > 1080 || videoHeight > 1920) {
              errorMessages.push(
                t('common.errors.fileErrors.videoResolution', { fileName: file.name }),
              )
            }
          }

          resolve()
        }
        video.src = URL.createObjectURL(file)
      })
    }
    return errorMessages.length > 0 ? errorMessages : null
  }

  const onDropFile = async (files) => {
    const validFiles = []
    const errorMessages = []
    const isGroupUpload = files.length > 1
    for (const file of files) {
      const validationErrors = await validateFile(file)
      if (validationErrors) {
        errorMessages.push({ file, errors: validationErrors })
      } else {
        validFiles.push(file)
      }
    }
    const hasValidFiles = validFiles.length > 0 ? true : false

    if (errorMessages.length > 0) {
      onChange(media, null, true, errorMessages, hasValidFiles, isGroupUpload)
    }

    if (validFiles.length > 0) {
      if (canReplace && fileToReplace) {
        dispatch({ type: actions.replaceFileAsync, payload: validFiles })
      } else if (canAddInternal) {
        dispatch({
          type: actions.addFilesAsync,
          payload: validFiles.slice(0, Math.min(validFiles.length, maxItems - media.length)),
        })
      }
    }
  }

  const removeFile = (fileId) => {
    const file = media.find((x) => x.id === fileId)

    if (file.index === state.fileToReplace?.index) dispatch({ type: actions.resetReplace })
    else dispatch({ type: actions.removeFileAsync, payload: file })
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDropFile,
    accept: 'image/jpeg, image/png, video/*',
    multiple: canAddInternal,
    onFileDialogCancel: () => {
      if (state.fileToReplace) dispatch({ type: actions.resetReplace })
    },
  })
  const mediaUploadsClasses = classnames({
    'media-uploads': true,
    'media-uploads-error': validationError,
    'mobile-view': isMobileSize,
    'justify-content-between': media.length,
    'justify-content-center': !media.length,
    readonly: readonly,
  })

  const onNext = (index) => {
    let newImage = null
    let newIndex = index + 1
    if (media.length - 1 >= newIndex) {
      newImage = media.find((file) => file.index === newIndex)
      setExpandedImage(newImage)
    }
  }

  const onBack = (index) => {
    let newImage = null
    let newIndex = index - 1
    if (newIndex >= 0) {
      newImage = media.find((file) => file.index === newIndex)
      setExpandedImage(newImage)
    }
  }

  return (
    <div>
      <div ref={ref} className={mediaUploadsClasses}>
        {!readonly && (
          <MediaDropzone
            media={media}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isMobileSize={isMobileSize}
            canAddInternal={canAddInternal}
            isCampaignRunning={isCampaignRunning}
          />
        )}
        <MediaList
          onRemove={(fileId) => removeFile(fileId)}
          media={media}
          readonly={readonly}
          canAdd={canAdd}
          canAddInternal={canAddInternal}
          reachedMaxLimit={reachedMaxLimit}
          canRemove={canRemove}
          canReplace={canReplace}
          onRetry={(file) => dispatch({ type: actions.retryUploadAsync, payload: file })}
          onReplaceStarted={(index) => {
            dispatch({ type: actions.startReplace, payload: index })
            open()
          }}
          isMobileSize={isMobileSize}
          setExpandedImage={setExpandedImage}
        />
      </div>
      {!!state.error && <p className="warning-red mt-2 text-left">{state.error}</p>}
      {expandedImage && (
        <PortalModal
          isOpen={expandedImage}
          className="full-height"
          onClickOutside={() => setExpandedImage(null)}
        >
          <div className="media-uploads_preview">
            <div className="media-uploads_preview_wrapper">
              <div>
                <div className="media-uploads_preview_header">
                  <div>
                    <div className="media-uploads_preview_header_title">
                      {expandedImage.filename ?? expandedImage.name}
                    </div>
                    <div>{filesize(expandedImage.size || 0)}</div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => setExpandedImage(null)}
                      className="media-uploads_preview_header_close-btn"
                    >
                      <CancelIcon
                        width={14}
                        className="media-uploads_preview_header_close-btn_icon"
                      />
                    </button>
                  </div>
                </div>
                <hr />
                <div className="media-uploads_preview_image">
                  {expandedImage.type && expandedImage.type.startsWith('video') ? (
                    <video controls src={expandedImage.url} title={expandedImage.name} />
                  ) : (
                    <img
                      src={expandedImage.preview || expandedImage.url}
                      alt={expandedImage.name}
                    />
                  )}
                </div>
              </div>

              <footer className="media-uploads_preview_navigation">
                <LeftArrowCircleIcon
                  onClick={() => onBack(expandedImage.index)}
                  className={`left ${expandedImage.index === 0 ? 'disabled' : ''}`}
                />
                <RightArrowCircleIcon
                  onClick={() => onNext(expandedImage.index)}
                  className={`right ${expandedImage.index === media.length - 1 ? 'disabled' : ''}`}
                />
              </footer>
            </div>
          </div>
        </PortalModal>
      )}
    </div>
  )
}

MediaUpload.propTypes = {
  initialMedia: PropTypes.arrayOf(object),
  onChange: PropTypes.func.isRequired,
  maxItems: PropTypes.number,
  readonly: PropTypes.bool,
  canAdd: PropTypes.bool,
  canRemove: PropTypes.bool,
  canReplace: PropTypes.bool,
  validationError: PropTypes.bool,
  isCampaignRunning: PropTypes.bool,
}

export default MediaUpload
