import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import { addDays, startOfDay } from 'date-fns'
import { Card } from 'react-bootstrap'
import Input from 'components/Shared/Input'
import minusIcon from 'images/icons/minus-circle.svg'

import DatePicker from 'react-datepicker'
import calenderIcon from 'images/icons/calender.svg'

const MIN_VAL = 0

const KeyFactsForm = ({ updateDate, fields, updateInput, index, removeField }) => {
  const { t, i18n } = useTranslation()

  const datePattern = fields.date.match(/\d{4}-\d{2}-\d{2}/) ? 'yyyy-MM-DD' : 'DD.MM.yyyy'
  const selectedDate =
    fields.date && fields.time
      ? moment(fields.date + ' ' + fields.time, `${datePattern} HH:mm`).toDate()
      : fields.date
        ? moment(fields.date, datePattern).toDate()
        : fields.time
          ? moment(fields.time, 'HH:mm').toDate()
          : null

  const capacityValidator = (val) => {
    if (val && val < MIN_VAL) {
      return { isValid: false, errorMsg: t('Simulation.form.errors.negativeError') }
    }
    if (val && val % 1 !== 0) {
      return { isValid: false, errorMsg: t('Simulation.form.errors.decimalError') }
    }

    return { isValid: true }
  }

  return (
    <div className="simulation-form__section simulation-form__key-facts">
      <Card className="card-default mb-0">
        <div className="card-header d-flex">
          <h2 className="card-title-default">
            {t('Simulation.dateAndLocationFormTitle')} {index > 0 ? index + 1 : ''}
          </h2>
          {index > 0 ? (
            <div className="minus-icon">
              <img src={minusIcon} onClick={() => removeField(index)} alt="removeIcon" />
            </div>
          ) : null}
        </div>
        <Card.Body className="pb-1">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-12 col-md-3 px-2 px-xl-3">
                  <div className="form-group input-cell input-cell--input input-cell--date separatePlaceholderBox">
                    <DatePicker
                      name="date"
                      separatePlaceholder
                      selected={selectedDate}
                      placeholderText={t('Simulation.form.eventDate')}
                      onChange={(date) => {
                        updateDate(date, index)
                      }}
                      dateFormat="dd.MM.yyyy"
                      minDate={addDays(startOfDay(new Date()), 2)}
                      locale={i18n.language}
                      required
                      autoComplete="off"
                    />
                    <img className="calenderIcon" src={calenderIcon} alt="" />
                    <label
                      className={`separatePlaceholder required ${fields.date ? 'hidden' : ''}`}
                    >
                      {t('Simulation.form.eventDate')}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-2 px-2 px-xl-3">
                  <div className="form-group input-cell input-cell--input input-cell--date">
                    <DatePicker
                      name="time"
                      separatePlaceholder
                      selected={selectedDate}
                      placeholderText={t('common.time')}
                      onChange={(date) => {
                        const newDate = new Date(selectedDate)
                        newDate.setHours(date.getHours())
                        newDate.setMinutes(date.getMinutes())
                        updateDate(newDate, index)
                      }}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      required
                      locale={i18n.language}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 px-2 px-xl-3">
                  <Input
                    separatePlaceholder={true}
                    placeholder={
                      fields.price === null || fields.price === undefined
                        ? t('Simulation.form.price')
                        : ''
                    }
                    value={fields.price}
                    type="number"
                    name="price"
                    onChange={(e) => updateInput(e, index)}
                    min={MIN_VAL}
                    validator={(val) => {
                      return {
                        isValid: val && val >= MIN_VAL,
                        errorMsg: t('Simulation.form.errors.negativeError'),
                      }
                    }}
                    errorBelow={true}
                    required={!fields.price}
                  />
                </div>
                <div className="col-12 col-md-3 px-2 px-xl-3">
                  <Input
                    maxLength={34}
                    separatePlaceholder={true}
                    placeholder={t('Simulation.form.city')}
                    value={fields.city}
                    name="city"
                    onChange={(e) => updateInput(e, index)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-2 px-xl-3">
              <Input
                separatePlaceholder={true}
                placeholder={t('Simulation.form.venue')}
                value={fields.venue}
                name="venue"
                onChange={(e) => updateInput(e, index)}
                required
              />
            </div>
            <div className="col-12 col-md-3 px-2 px-xl-3">
              <Input
                separatePlaceholder={true}
                placeholder={t('Simulation.form.hall')}
                value={fields.hall}
                name="hall"
                onChange={(e) => updateInput(e, index)}
                required
              />
            </div>
            <div className="col-12 col-md-3 px-2 px-xl-3">
              <Input
                separatePlaceholder={true}
                placeholder={fields.capacity ? '' : t('Simulation.form.capacity')}
                value={fields.capacity}
                type="number"
                name="capacity"
                onChange={(e) => updateInput(e, index)}
                min={MIN_VAL}
                validator={capacityValidator}
                errorBelow={true}
                required={!fields.capacity}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

KeyFactsForm.propTypes = {
  updateDate: PropTypes.func,
  fields: PropTypes.object,
  updateInput: PropTypes.func,
  index: PropTypes.number,
  removeField: PropTypes.func,
}

export default KeyFactsForm
