import React, { useEffect, useReducer, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useOutsideCheckClick } from 'hooks/UseOnClickOutside'

import { Card } from 'react-bootstrap'
import dotsMenu from 'images/icons/dots-menu.svg'

import Layout from 'components/Layout'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import Button from 'components/Shared/Button'
import { DeleteContent, MergeContent } from 'components/PackageBuilder/Modals/MergeModalContent'
import UtilityButtons from 'components/PackageBuilder/components/UtilityButtons'
import PreviousPackagesList from 'components/PackageBuilder/Results/PreviousPackagesList'

import { initStateObject, objectReducer } from 'reducers/default'
import { fetchPackages, mergePackages } from 'api/packageBuilder'

import mockData from '../mock/packages.json'
import PortalModal from 'components/Shared/PortalModal'
import { demoAccounts } from '../constants'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'
/**
 * Dashboard page for a users previously built Packages.
 * It displays a list of packages that Link to package-builder/results passing a packageId url prop
 * @Tags( page, packageBuilder, layout, logged-users, api )
 * @SuggestedTags( packageBuilder )
 * @Endpoints( /package-builder/ )
 * @ApiLogic( ../api/package-builder/ )
 */
const MobileMenu = ({ menuOpen, toggleMenuOpen, handleUtilityAction }) => {
  const menuRef = useRef(null)
  useOutsideCheckClick(menuRef, toggleMenuOpen, menuOpen)
  return (
    <div
      ref={menuRef}
      className={`package-builder-page_utility-buttons_mobile-menu_wrapper ${menuOpen ? 'active' : ''}`}
    >
      <button
        className="package-builder-page_utility-buttons_mobile-menu_btn"
        onClick={() => handleUtilityAction('MERGE')}
      >
        Merge
      </button>
    </div>
  )
}

MobileMenu.propTypes = {
  menuOpen: PropTypes.bool,
  toggleMenuOpen: PropTypes.func,
  handleUtilityAction: PropTypes.func,
}

const PackageBuilderDashboard = () => {
  const { t } = useTranslation()
  const [previousPackages, dispatch] = useReducer(objectReducer, initStateObject)
  const [highlightedPackages, setHighlightedPackages] = useState([])
  const [utilityModeActive, setUtilityModeActive] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [updating, setUpdating] = useState(false)
  const [mobileMenuActive, toggleMobileMenuActive] = useState(false)
  const hasBackhaulAccess = useHasPermission(Permissions.backhaul)

  const partnerDetails = localStorage.getItem('partnerDetails')
  const isDemoAccount = partnerDetails && demoAccounts.includes(JSON.parse(partnerDetails).clientId)

  const handlePackageSelect = (selectedPackage, e) => {
    e.stopPropagation()
    e.preventDefault()
    if (highlightedPackages.indexOf(selectedPackage) > -1) {
      const currentHighlighted = [...highlightedPackages]
      const filteredPackageList = currentHighlighted.filter(
        (pack) => pack.id !== selectedPackage.id,
      )
      setHighlightedPackages(filteredPackageList)
    } else {
      // add package
      const currentHighlighted = [...highlightedPackages]
      currentHighlighted.push(selectedPackage)
      setHighlightedPackages(currentHighlighted)
    }
  }

  const handleUtilityAction = (type) => {
    if (type === 'MERGE') {
      setModalType('MERGE')
      setModalOpen(true)
      toggleMobileMenuActive(false)
    }
    if (type === 'DELETE') {
      setModalType('DELETE')
      setModalOpen(true)
      toggleMobileMenuActive(false)
    }
  }

  const handleSelectAll = () => {
    const useableData = isDemoAccount
      ? mockData.data
      : Array.isArray(previousPackages.message)
        ? previousPackages.message
        : []

    if (highlightedPackages && highlightedPackages.sort() === useableData.sort()) {
      setHighlightedPackages([])
    } else if (!utilityModeActive) {
      setHighlightedPackages(useableData)
    }
  }

  const handleDelete = () => {
    setUpdating(true)
    try {
      // TODO: API requests added here
      console.log('a baackground delete action request is being made')
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => {
        setModalOpen(false)
        setModalType(null)
        setHighlightedPackages([])
        setUpdating(false)
        toggleMobileMenuActive(false)
      }, 2000)
    }
  }

  const handleMerge = async () => {
    setUpdating(true)
    try {
      // TODO: API requests added here
      const highlighedIds = highlightedPackages.map((p) => p.id)
      const { error, success, content } = await mergePackages(highlighedIds)

      if (success) {
        fetchPackages(dispatch)
      } else if (error) {
        console.error(content)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setModalOpen(false)
      setModalType(null)
      setHighlightedPackages([])
      setUpdating(false)
      toggleMobileMenuActive(false)
    }
  }

  const ModalContent = () => {
    if (!modalOpen) return

    if (modalType === 'MERGE' && highlightedPackages.length) {
      return (
        <MergeContent
          updating={updating}
          setModalOpen={setModalOpen}
          setModalType={setModalType}
          handleMerge={handleMerge}
          highlightedPackages={highlightedPackages}
        />
      )
    }
    if (modalType === 'DELETE' && highlightedPackages.length) {
      return (
        <DeleteContent
          updating={updating}
          setModalOpen={setModalOpen}
          setModalType={setModalType}
          handleDelete={handleDelete}
          highlightedPackages={highlightedPackages}
        />
      )
    }
  }

  useEffect(() => {
    // api call to check for
    fetchPackages(dispatch)
  }, [])

  return (
    <Layout
      loggedIn
      title={t('PackageBuilder.title')}
      mobileTopBar={{ back: true, centerTitle: t('PackageBuilder.title') }}
    >
      <Helmet bodyAttributes={{ class: 'package-builder mobile-bg-white' }} />
      <div
        id="package-builder-previous-packages"
        data-testid="package-builder-previous-packages"
        className="package-builder-page"
      >
        <div
          style={{ minHeight: '100vh' }}
          className="container-fluid hide-scroll-bar"
          data-cy="package-builder-dashboard-screen"
        >
          <div className="d-flex hide-mobile">
            <h1 className="main-title">Backhaul</h1>
          </div>
          <Card className="package-builder-page_outer-wrapper card my-4" style={{ minHeight: 250 }}>
            <div className="package-builder-page_previous-packages_header">
              {isDemoAccount || (previousPackages && previousPackages.message) ? (
                <UtilityButtons
                  isActive={highlightedPackages && highlightedPackages.length}
                  canMerge={false}
                  handleClick={handleUtilityAction}
                  selectAll={handleSelectAll}
                  isAllSelected={
                    highlightedPackages &&
                    highlightedPackages.length ===
                      (isDemoAccount ? mockData.data.length : previousPackages.message.length)
                  }
                />
              ) : null}
              <div className="package-builder-page_previous-packages_header">
                <Button
                  disabled={!hasBackhaulAccess}
                  linkTo={'/package-builder/create'}
                  text={t('PackageBuilder.createNewPackage')}
                />
              </div>
            </div>

            {highlightedPackages && highlightedPackages.length > 0 ? (
              <div className="package-builder-page_previous-packages_title-header_wrapper hide-desktop">
                <h4 className="package-builder-page_sub-headlines">{t('')}</h4>
                <img
                  onClick={() => toggleMobileMenuActive(!mobileMenuActive)}
                  className="package-builder-page_previous-packages_title-header_more-dots"
                  alt="more actions"
                  src={dotsMenu}
                />
                <MobileMenu
                  menuOpen={mobileMenuActive}
                  toggleMenuOpen={toggleMobileMenuActive}
                  handleUtilityAction={handleUtilityAction}
                />
              </div>
            ) : null}
            {previousPackages.loading ? (
              <>
                <LoadingSpinner size={SpinnerSize.LARGE} showText={false} />
              </>
            ) : isDemoAccount ||
              (!previousPackages.error &&
                previousPackages.message &&
                previousPackages.message.length) > 0 ? (
              <div data-cy="package-builder-previous-packages-list">
                <PreviousPackagesList
                  previousPackages={isDemoAccount ? mockData.data : previousPackages.message}
                  setHighlightedPackages={setHighlightedPackages}
                  highlightedPackages={highlightedPackages}
                  handleSelect={handlePackageSelect}
                  setUtilityModeActive={setUtilityModeActive}
                  utilityModeActive={highlightedPackages && highlightedPackages.length > 0}
                  previousPackageStats={isDemoAccount ? mockData.data : previousPackages.content}
                />
              </div>
            ) : (
              <div
                data-cy="package-builder-no-previous-packages-message"
                className="package-builder-page_dashboard_wrapper"
              >
                <br />
                <br />
                <p>You do not have any packages created.</p>
                <br />
                <br />
                <Button
                  disabled={!hasBackhaulAccess}
                  linkTo={'/package-builder/create'}
                  text={t('PackageBuilder.createNewPackage')}
                />
              </div>
            )}
          </Card>
        </div>
        {modalOpen && modalType ? (
          // <Modal data-cy="utlity-confirm-modal" hideHeader={true} hideCloseBtn={true} open={modalOpen} title={null} showCancel={false} mainContent={<ModalContent />} onClickOutside={() => setModalOpen(false)} />
          <PortalModal isOpen={modalOpen} onClickOutside={() => setModalOpen(false)}>
            <ModalContent />
          </PortalModal>
        ) : null}
      </div>
    </Layout>
  )
}

export default PackageBuilderDashboard
