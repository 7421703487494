import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TARGETING_MODES, getDefaultTargetingMode } from '../targeting'
import { actions as rangeLocationActions, shouldOverrideLocalState } from './rangeLocationsReducer'
import { useTargeting } from '../TargetingContext'

import { SecondaryButton } from 'components/Shared/Button'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import RangeLocations from './RangeLocations'
import RangeLocationsContextProvider, {
  useRangeLocations,
  useRangeLocationsDispatch,
} from './RangeLocationsContextProvider'
import { getRadiusVariations } from './rangeLocationsReducer'

const propTypes = {
  tc: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

function RangeLocationsListContent({ tc, onChange }) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { value: targeting } = useTargeting()
  const tcTargeting = useMemo(() => targeting.find((x) => x.tc === tc), [targeting, tc])
  const { targetingMode } = useMemo(
    () => targeting.find((x) => x.tc === tc) ?? { tc },
    [targeting, tc],
  )
  const { locations } = useRangeLocations()
  const dispatch = useRangeLocationsDispatch()

  const onRangeLocationsChange = useCallback(
    (locs) => {
      locs = locs || locations
      const rangeLocations = locs.reduce(
        (
          acc,
          {
            data: { key, name, country, country_code, country_codes, country_name, type },
            radius,
            qualifier,
          },
        ) => {
          const validCountryCodes = Array.isArray(country_codes) ? country_codes : []
          const location = {
            key,
            name,
            country: country || country_code || '',
            country_name: country_name || '',
            type,
            country_code: country_code || '',
            country_codes: validCountryCodes,
          }
          let locationWrapper = { location }
          if (location.type === 'city') {
            locationWrapper = { ...locationWrapper, ...getRadiusVariations(radius) }
          }
          if (qualifier === 'exclude') {
            acc.excluded.push(locationWrapper)
          } else {
            acc.included.push(locationWrapper)
          }
          return acc
        },
        { included: [], excluded: [] },
      )

      onChange({ rangeLocations })
    },
    [onChange, locations],
  )

  useEffect(() => {
    // Listen to changes to tcTargeting and overwrite the local context but only on closed
    // This allows us to propagate valid range locations targeting settings to other tcs
    // that don't have a valid targting setting yet.
    if (!isOpen && tcTargeting && shouldOverrideLocalState(tcTargeting, locations)) {
      dispatch({ type: rangeLocationActions.reset, payload: { tcTargeting } })
    }
  }, [tcTargeting, isOpen, locations, dispatch])

  const hasPrismaAccess = hasPrismaAccessTier()
  const defaultTargetingMode = getDefaultTargetingMode(hasPrismaAccess)
  if (
    (!targetingMode && defaultTargetingMode !== TARGETING_MODES.rangeLocations) ||
    (targetingMode && targetingMode !== TARGETING_MODES.rangeLocations)
  ) {
    return null
  }

  return (
    <div className="range-locations">
      {isOpen && (
        <RangeLocations
          tc={tc}
          isOpen={isOpen}
          onSave={onRangeLocationsChange}
          setOpen={(open) => setIsOpen(open)}
        />
      )}
      <SecondaryButton
        text={t('common.edit')}
        color="navy"
        size="small"
        fullWidth={false}
        type="button"
        classNames="geo-targeting__button"
        onClick={(e) => {
          e.preventDefault()
          setIsOpen(!isOpen)
        }}
      />
    </div>
  )
}

RangeLocationsListContent.propTypes = propTypes

export default function RangeLocationsList(props) {
  return (
    <RangeLocationsContextProvider tc={props.tc}>
      <RangeLocationsListContent {...props} />
    </RangeLocationsContextProvider>
  )
}

RangeLocationsList.propTypes = propTypes
