export const demoAccounts = ['partner-id-entertainment', 'partner-id-marketing', 'partner-id-sport', 'partner-id-test']
const constants = {
  API_URL: process.env.REACT_APP_API,
  CR_URL: process.env.REACT_APP_CR_API,
  APP_TIMEOUT: process.env.REACT_APP_APP_TIMEOUT ? process.env.REACT_APP_APP_TIMEOUT : 12000,
  SENTRY_SAMPLING_RATE: process.env.REACT_APP_SENTRY_SAMPLING_RATE,
  MAX_IMG_UPLOAD_SIZE: Number(
    process.env.REACT_APP_MAX_UPLOAD_MB_IMAGE ? process.env.REACT_APP_MAX_UPLOAD_MB_IMAGE : 1,
  ),
  MAX_VIDEO_UPLOAD_SIZE: Number(
    process.env.REACT_APP_MAX_UPLOAD_MB_VIDEO ? process.env.REACT_APP_MAX_UPLOAD_MB_VIDEO : 1,
  ),
  NOTIFICATION_PULL_INTERVAL: Number(
    process.env.REACT_APP_NOTIFICATION_PULL_INTERVAL ? process.env.REACT_APP_NOTIFICATION_PULL_INTERVAL : 10000,
  ),

  //eslint-disable-next-line no-useless-escape
  URL_VALIDATION_REGEX:
    /^(http(s)?:\/\/|www\.)[a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#!?|&//=]*)$/,
  API_VERSION: process.env.REACT_APP_API_VERSION || 'v3',
}

export default constants
