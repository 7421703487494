import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'

import { NotificationDispatchContext, actions } from './notifications'
import { FAIL, LOADING, OVERWRITE, arrayReducer, initStateArray } from 'reducers/default'
import { notificationsApi } from 'api/notifications'

import LoadingSpinner from 'components/Shared/LoadingSpinner'
import { MultiSelect } from 'components/Shared/Filters/MultiSelect'
import { DropDownFilter } from 'components/Shared/Filters/DropdownFilter'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

export default function TopicsFilter() {
  const dispatch = useContext(NotificationDispatchContext)
  const [topics, dispatchTopicsAction] = useReducer(arrayReducer, initStateArray)
  const [selectedTopics, setSelectedTopics] = useState([])
  const hasPrismaAccess = hasPrismaAccessTier()
  const { t } = useTranslation()

  useEffect(() => {
    const fetchNotificationTopics = async () => {
      dispatchTopicsAction({ type: LOADING })
      try {
        const topics = await notificationsApi.getTopics()
        dispatchTopicsAction({
          type: OVERWRITE,
          payload: {
            items: topics.filter(x => !hasPrismaAccess || x.toLowerCase() !== 'event').map((x) => ({
              id: x,
              title: x,
            })),
          },
        })
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        dispatchTopicsAction({ type: FAIL })
      }
    }

    fetchNotificationTopics()
  }, [hasPrismaAccess])

  return (
    <DropDownFilter
      title={t('Notifications.filters.topics')}
      onSubmit={() => {
        dispatch({
          type: actions.fetchNotificationsPageAsync,
          payload: {
            filters: {
              notificationTopics: selectedTopics,
            },
            page: 1,
          },
        })
      }}
      onReset={() => {
        if (selectedTopics.length === 0) return

        setSelectedTopics([])
        dispatch({
          type: actions.fetchNotificationsPageAsync,
          payload: {
            filters: {
              notificationTopics: [],
            },
            page: 1,
          },
        })
      }}
      isActive={selectedTopics.length > 0}
    >
      <>
        {topics.loading && <LoadingSpinner size="small" />}
        {!topics.loading && (
          <MultiSelect
            items={topics.items.sort((a, b) => a.title.localeCompare(b.title)).map((x) => ({
              ...x,
              title: x.title.charAt(0).toUpperCase() + x.title.slice(1).toLowerCase(),
            }))}
            selectedIds={selectedTopics}
            searchLabel={t('Notifications.filters.searchTopics')}
            onChange={(id) => {
              setSelectedTopics((selectedIds) => {
                return selectedIds.includes(id) ? selectedIds.filter((x) => x !== id) : [...selectedIds, id]
              })
            }}
          />
        )}
      </>
    </DropDownFilter>
  )
}
