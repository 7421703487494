import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDistance } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

import { useCampaignSetup, useCampaignSetupDispatch } from '../Context/CampaignSetupContext'
import { actions as setupActions } from '../Context/actionTypes'

export default function AutosaveStatus() {
  const { t, i18n } = useTranslation()
  const { error, lastSyncedAt, loading } = useCampaignSetup()
  const dispatch = useCampaignSetupDispatch()

  const [currentDate, setCurrentDate] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(Date.now()), 10000)

    return () => clearInterval(interval)
  }, [])

  if (!lastSyncedAt) return null

  return (
    <div className="autosave-status">
      {error ? (
        <div className="autosave-error">
          <p>
            {t('Recommendations.campaign.setup.autoSave.fail')}
            &nbsp;&nbsp;
            <button disabled={loading} onClick={() => dispatch({ type: setupActions.syncAsync })}>
              {t('Recommendations.campaign.setup.autoSave.retry')}
            </button>
          </p>
        </div>
      ) : (
        <p className="autosave-success">
          {t('Recommendations.campaign.setup.autoSave.success', {
            distance: formatDistance(lastSyncedAt, currentDate, {
              addSuffix: true,
              locale: i18n.language === 'de' ? de : enUS,
            }),
          })}
        </p>
      )}
    </div>
  )
}
