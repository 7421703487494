import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useRedirectOnResize from 'hooks/useRedirectOnResize'
import { Redirect } from 'react-router-dom'

import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import ErrorMessage from 'components/Shared/ErrorMessage'

import { DrawerIcon, EventIcon, CashIcon } from 'images'
import CustomerProfile from './CustomerProfile'
import VisitedEvents from './VisitedEvents'
import CustomerHeader from './CustomerHeader'
import CustomerStatisticsItem from './CustomerStatisticsItem'
import customersApi from 'api/customers'
import { codifyAPIError, numberWithThousandsSeparatorsWithCurrencyPrefix } from 'utils/helpers'
import CustomProperties from './CustomProperties'

function Content({ id }) {
  const [t] = useTranslation()
  const [customerDetails, setCustomerDetails] = useState(null)
  const [salesSummary, setSalesSummary] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const currency = (localStorage.getItem('userCurrency') || 'EUR').toUpperCase()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const customerDetailsResponse = await customersApi.fetchCustomerDetailsAsync(id)
        if (customerDetailsResponse.error) {
          throw customerDetailsResponse.error
        }

        setCustomerDetails(customerDetailsResponse.data)
        const salesSummaryResponse = await customersApi.fetchCustomerSalesSummaryAsync(id)
        if (salesSummaryResponse.error) {
          throw salesSummaryResponse.error
        }
        setSalesSummary(salesSummaryResponse.data)
      } catch (error) {
        setError(codifyAPIError(error))
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [id])

  return (
    <div className="customer-details__content">
      {loading && (
        <LoadingSpinner
          className="mt-5"
          showText
          size={SpinnerSize.SMALL}
          title={t('common.loading')}
        />
      )}
      {error && (
        <ErrorMessage classNames="mt-5" danger>
          {t(error)}
        </ErrorMessage>
      )}
      {!loading && !error && customerDetails && salesSummary && (
        <>
          <div className="customer-details__header">
            <CustomerHeader customerDetails={customerDetails} salesSummary={salesSummary} />
          </div>
          <div className="customer-details__stats">
            <CustomerStatisticsItem
              title={t('Sentinel.results.customerDetails.totalEvents')}
              renderIcon={() => <DrawerIcon className="navy" />}
              value={salesSummary.sales_per_event.length}
            />
            <CustomerStatisticsItem
              title={t('Sentinel.results.customerDetails.totalTickets')}
              renderIcon={() => <EventIcon className="stroke-navy" />}
              value={salesSummary.global_revenue.total_tickets ?? 0}
            />
            <CustomerStatisticsItem
              title={t('Sentinel.results.customerDetails.totalRevenue')}
              renderIcon={() => <CashIcon className="navy" />}
              value={numberWithThousandsSeparatorsWithCurrencyPrefix(
                salesSummary.global_revenue.total_purchase_value ?? 0,
                { currency, maxFractionDigits: 2 },
              )}
            />
          </div>
          <CustomerProfile customerDetails={customerDetails} />
          <VisitedEvents salesSummary={salesSummary} />
          <CustomProperties customerDetails={customerDetails} />
        </>
      )}
    </div>
  )
}

Content.propTypes = {
  id: PropTypes.string.isRequired,
}

export default function CustomerDetails({ id }) {
  const shouldRedirect = useRedirectOnResize(991)

  if (shouldRedirect) {
    return <Redirect to={id ? `/sentinel/results/${id}` : '/sentinel'} />
  }

  return (
    <div className="customer-details">
      <Content id={id} />
    </div>
  )
}

CustomerDetails.propTypes = {
  id: PropTypes.string.isRequired,
}
