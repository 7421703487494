import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SecondaryButton } from 'components/Shared/Button'
import { useRangeLocations, useRangeLocationsDispatch } from './RangeLocationsContextProvider'

export default function RangeLocationsActions({ onSave }) {
  const { t } = useTranslation()
  const { locations } = useRangeLocations()
  const dispatch = useRangeLocationsDispatch()
  const isAllLocationsExcluded = useMemo(() => {
    return locations.length > 0 && locations.every((location) => location.qualifier === 'exclude')
  }, [locations])

  const clearDisabled = useMemo(() => locations.length === 0, [locations])
  const saveDisabled = useMemo(
    () => isAllLocationsExcluded || locations.some((x) => x.loading),
    [isAllLocationsExcluded, locations],
  )

  return (
    <div className="range-locations_actions">
      {isAllLocationsExcluded ? (
        <p className="error-msg error-msg--small" style={{ color: 'red' }}>
          {t('Recommendations.geoTargeting.rangeLocations.allLocationsExcludedError')}
        </p>
      ) : (
        <SecondaryButton
          text={t('common.clearAll')}
          color="navy"
          onClick={() => dispatch({ type: 'clearAll' })}
          type="button"
          disabled={clearDisabled}
        />
      )}

      <SecondaryButton
        text={t('common.save')}
        color="orange"
        disabled={saveDisabled}
        type="button"
        onClick={onSave}
      />
    </div>
  )
}

RangeLocationsActions.propTypes = {
  onSave: PropTypes.func.isRequired,
  postalCodeOptions: PropTypes.array.isRequired,
  unmatchedPostalCodes: PropTypes.array.isRequired,
}
