import { numberWithThousandsSeparators } from 'utils/helpers'
const customFormatter = ({ row, column, isRowSelected, onRowChange }, t) => {
  if (column.customFormatter) {
    return column.customFormatter({ row, column, isRowSelected, onRowChange }, t) ?? ''
  }

  const tKey = `Sentinel.results.${column.entity}.${row[column.key]?.toString() ?? ''}`
  return t([tKey, row[column.key]?.toString() ?? ''])
}

// format strings like "rock_pop" into "Rock Pop"
const formatString = (value) => {
  if (!value) return ''
  return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const userColumns = [
  { entity: 'customers', key: 'id', name: 'ID' },
  { entity: 'customers', key: 'salutation', name: 'Salutation', minWidth: 140 },
  { entity: 'customers', key: 'first_name', name: 'First Name', minWidth: 140 },
  { entity: 'customers', key: 'last_name', name: 'Last Name', minWidth: 140 },
  { entity: 'customers', key: 'street', name: 'Street' },
  { entity: 'customers', key: 'zip_code', name: 'Zip Code', minWidth: 140 },
  { entity: 'customers', key: 'city', name: 'City' },
  { entity: 'customers', key: 'email', name: 'Email' },
  { entity: 'customers', key: 'email_2', name: 'Email 2' },
  { entity: 'customers', key: 'phone1', name: 'Phone' },
  { entity: 'customers', key: 'phone2', name: 'Phone 2' },
  {
    entity: 'customers',
    key: 'ads_opt_out',
    name: 'Ads Opt Out',
    minWidth: 150,
  },
]

export const eventColumns = [
  { entity: 'events', key: 'id', name: 'ID' },
  { entity: 'events', key: 'title', name: 'Title' },
  { entity: 'events', key: 'description', name: 'Description', minWidth: 140 },
  {
    entity: 'events',
    key: 'category',
    name: 'Category',
    customFormatter: ({ row, column }) => {
      return formatString(row[column.key])
    },
  },
  { entity: 'events', key: 'date', name: 'Date' },
  {
    entity: 'events',
    key: 'start',
    name: 'Start',
    customFormatter: ({ row, column }) => {
      return row[column.key]?.slice(0, 5)
    },
  },
  {
    entity: 'events',
    key: 'end',
    name: 'End',
    customFormatter: ({ row, column }) => {
      return row[column.key]?.slice(0, 5)
    },
  },
  {
    entity: 'events',
    key: 'capacity',
    name: 'Capacity',
    customFormatter: ({ row, column }) => {
      return numberWithThousandsSeparators(row[column.key])
    },
  },
  {
    entity: 'events',
    key: 'price_category',
    name: 'Price Category',
    customFormatter: ({ row, column }) => {
      return numberWithThousandsSeparators(row[column.key])
    },
    minWidth: 140,
  },
  { entity: 'events', key: 'city', name: 'City' },
  {
    entity: 'events',
    key: 'artist_name',
    name: 'Artist',
    customFormatter: ({ row }) => {
      return row.lineups?.map((artist) => artist.artist_name).join(' | ') || ''
    },
    minWidth: 140,
  },
  {
    entity: 'events',
    key: 'composer',
    name: 'Author',
    customFormatter: ({ row }) => {
      return row.setlists?.map((composer) => composer.composer).join(' | ') || ''
    },
    minWidth: 140,
  },
  {
    entity: 'events',
    key: 'composer_title',
    name: 'Setlist',
    customFormatter: ({ row }) => {
      return row.setlists?.map((composer) => composer.title).join(' | ') || ''
    },
    minWidth: 140,
  },
]

export const transactionColumns = [
  { entity: 'transactions', key: 'id', name: 'ID' },
  { entity: 'transactions', key: 'event_id', name: 'Event ID' },
  { entity: 'transactions', key: 'customer_id', name: 'Customer ID', minWidth: 100 },
  { entity: 'transactions', key: 'invoice_id', name: 'Invoice ID', minWidth: 140 },
  {
    entity: 'transactions',
    key: 'ticket_type',
    name: 'Ticket Type',
    minWidth: 100,
    customFormatter: ({ row, column }) => {
      return formatString(row[column.key])
    },
  },
  { entity: 'transactions', key: 'date', name: 'Date' },
  {
    entity: 'transactions',
    key: 'time',
    name: 'Time',
    customFormatter: ({ row, column }) => {
      return row[column.key]?.slice(0, 5)
    },
  },
  {
    entity: 'transactions',
    key: 'sold_price',
    name: 'Sold Price',
    customFormatter: ({ row, column }) => {
      return numberWithThousandsSeparators(row[column.key])
    },
  },
  {
    entity: 'transactions',
    key: 'ground_price',
    name: 'Ground Price',
    customFormatter: ({ row, column }) => {
      return numberWithThousandsSeparators(row[column.key])
    },
    minWidth: 100,
  },
  {
    entity: 'transactions',
    key: 'discount',
    name: 'Discount',
    customFormatter: ({ row, column }) => {
      return numberWithThousandsSeparators(row[column.key])
    },
  },
  { entity: 'transactions', key: 'ticket_category', name: 'Ticket Category', minWidth: 150 },
  { entity: 'transactions', key: 'currency', name: 'Currency' },
  { entity: 'transactions', key: 'payment_method', name: 'Payment Method', minWidth: 150 },
  { entity: 'transactions', key: 'seat_id', name: 'Seat ID' },
]

export const getColumns = (t) => ({
  customers: userColumns.map((column) => {
    return {
      ...column,
      name: t([`Sentinel.results.customers.columns.${column.key}`, column.name]),
      formatter: (data) => customFormatter(data, t),
    }
  }),
  transactions: transactionColumns.map((column) => ({
    ...column,
    name: t([`Sentinel.results.transactions.columns.${column.key}`, column.name]),
    formatter: (data) => customFormatter(data, t),
  })),
  events: eventColumns.map((column) => ({
    ...column,
    name: t([`Sentinel.results.events.columns.${column.key}`, column.name]),
    formatter: (data) => customFormatter(data, t),
  })),
})
