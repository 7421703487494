import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Table } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

import FDAccordion from 'components/Shared/FDAccordion'
import Paginator from 'components/Shared/Paginator'
import { numberWithThousandsSeparatorsWithCurrencyPrefix } from 'utils/helpers'

export default function VisitedEvents({ salesSummary }) {
  const { t } = useTranslation()
  const [activeKeys, setActiveKeys] = useState([])
  const pageSize = 10
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(salesSummary.sales_per_event.length / pageSize)
  const currency = (localStorage.getItem('userCurrency') || 'EUR').toUpperCase()

  const currentPage = useMemo(() => {
    const start = (page - 1) * pageSize
    const end = start + pageSize
    return salesSummary.sales_per_event.slice(start, end)
  }, [page, salesSummary.sales_per_event])

  return (
    <div className={cn('customer-details__visited-events', { open: activeKeys.length > 0 })}>
      <FDAccordion defaultActiveKeys={activeKeys} onChange={setActiveKeys}>
        <FDAccordion.Item key={0}>
          <FDAccordion.Toggle>
            <div className="d-flex justify-content-between align-items-center">
              <div>{t('Sentinel.results.customerDetails.visitedEvents.title')}</div>
              <div className={cn('accordion-arrow', { open: activeKeys.length > 0 })} />
            </div>
          </FDAccordion.Toggle>
          <FDAccordion.Body>
            <div className="customer-details__profile__content">
              {salesSummary.sales_per_event.length === 0 && (
                <div className="sentinel_results__empty-msg">{t('Sentinel.results.emptyMsg')}</div>
              )}
              {salesSummary.sales_per_event.length > 0 && (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t('Sentinel.results.customerDetails.visitedEvents.date')}</th>
                      <th>{t('Sentinel.results.customerDetails.visitedEvents.eventTitle')}</th>
                      <th>{t('Sentinel.results.customerDetails.visitedEvents.numberOfTickets')}</th>
                      <th>{t('Sentinel.results.customerDetails.visitedEvents.purchase')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPage.map((event, index) => (
                      <tr key={index}>
                        <td>{event.event_date}</td>
                        <td>{event.title}</td>
                        <td>{event.ticket_count}</td>
                        <td>
                          {numberWithThousandsSeparatorsWithCurrencyPrefix(event.total_spent ?? 0, {
                            currency,
                            maxFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
            <Paginator
              onPageClicked={setPage}
              page={page}
              totalPages={totalPages}
              pageSize={pageSize}
              totalItems={salesSummary.sales_per_event.length}
              hideWhenSinglePage
            />
          </FDAccordion.Body>
        </FDAccordion.Item>
      </FDAccordion>
    </div>
  )
}

VisitedEvents.propTypes = {
  salesSummary: PropTypes.shape({
    sales_per_event: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        event_date: PropTypes.string,
        start_time: PropTypes.string,
        venue_name: PropTypes.string,
        hall_name: PropTypes.string,
        ticket_count: PropTypes.number,
        total_spent: PropTypes.number,
      }),
    ),
  }),
}
