import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cln from 'classnames'
import { useTranslation } from 'react-i18next'

import { TARGETING_MODES } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'

import allCountries from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/PostalCodes/countries.json'
import { isObjectEmpty } from 'utils/helpers'

const Layout = ({ children, borderless = false }) => {
  const { t } = useTranslation()

  return (
    <div className="recommendation__details__running__section recommendation__details__running__section--targeting">
      <p className="recommendation__details__running__section__title">
        {t('Recommendations.campaign.published.geoTargeting')}
      </p>
      <p
        className={cln('recommendation__details__running__section__body', {
          borderless,
        })}
      >
        {children}
      </p>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  borderless: PropTypes.bool,
}

export default function Targeting({ tc, tc_run_id, targeting }) {
  const { t } = useTranslation()

  const _targeting = useMemo(() => {
    return (
      targeting?.[0] ?? {
        targetingMode: TARGETING_MODES.fdTargeting,
        tc,
        tc_run_id,
      }
    )
  }, [targeting, tc, tc_run_id])

  if (_targeting.targetingMode === TARGETING_MODES.fdTargeting) {
    return (
      <Layout targetingMode={_targeting.targetingMode}>
        {t(`Recommendations.geoTargeting.options.${_targeting.targetingMode}`)}
      </Layout>
    )
  }

  if (_targeting.targetingMode === TARGETING_MODES.postalCodes) {
    const countries = _targeting.postalCodes.countries

    if (isObjectEmpty(countries)) {
      return (
        <Layout targetingMode={_targeting.targetingMode}>
          {t('Recommendations.campaign.published.noPostalCodesAdded')}
        </Layout>
      )
    }

    const mappedCountries = Object.keys(countries).map((countryKey) => {
      const country = allCountries[countryKey]

      return {
        name: country.name,
        key: countryKey,
        totalPostalCodes: countries[countryKey].length,
      }
    })

    return (
      <Layout borderless>
        {mappedCountries.map((country) => (
          <div key={country.key} className="postal-codes__country">
            <div className="postal-codes__country__container">
              {t('Recommendations.campaign.published.geoTargetingCountry', {
                country: country.name,
                countPostalCodes: country.totalPostalCodes,
              })}
            </div>
          </div>
        ))}
      </Layout>
    )
  }

  if (_targeting.targetingMode === TARGETING_MODES.rangeLocations) {
    const { included = [], excluded = [] } = _targeting.rangeLocations

    if ((!included || included.length === 0) && (!excluded || excluded.length === 0)) {
      return (
        <Layout targetingMode={_targeting.targetingMode}>
          {t('Recommendations.campaign.published.noRangeLocationsAdded')}
        </Layout>
      )
    }

    const mappedLocations = [...included, ...excluded].reduce((acc, location) => {
      const countryName = location.location.country_name
      const countryGroupName = !countryName && location.location.name ? location.location.name : ''
      const existingEntry = acc.find((entry) => entry.countryName === countryName)
      const hasCity = location.location.type === 'city'
      const initialCitiesCount = hasCity ? 1 : 0

      if (existingEntry) {
        existingEntry.citiesCount += 1
      } else {
        const newEntry = {
          countryGroupName: countryGroupName || null,
          countryName: countryName || null,
          citiesCount: initialCitiesCount,
        }
        acc.push(newEntry)
      }

      return acc
    }, [])

    const sortedLocations = mappedLocations.sort((a, b) => {
      if (a.citiesCount > 0 && b.citiesCount === 0) return -1
      if (a.citiesCount === 0 && b.citiesCount > 0) return 1

      if (a.citiesCount === 0 && b.citiesCount === 0) {
        if (a.countryGroupName && !b.countryGroupName) return -1
        if (!a.countryGroupName && b.countryGroupName) return 1
      }

      return 0
    })

    return (
      <Layout borderless>
        {sortedLocations.map(({ countryGroupName, countryName, citiesCount }, index) => (
          <div
            key={`${countryGroupName || 'NoGroup'}-${countryName || 'NoCountry'}-${index}`}
            className="range-locations__entry"
          >
            {countryGroupName && (
              <div className="range-locations__country">
                <div className="range-locations__country__container">
                  {t('Recommendations.campaign.published.geoTargetingRegion', {
                    countryGroup: countryGroupName,
                  })}
                </div>
              </div>
            )}

            {countryName && (
              <div className="range-locations__country">
                <div className="range-locations__country__container">
                  {citiesCount > 0
                    ? t('Recommendations.campaign.published.geoTargetingLocation', {
                        country: countryName,
                        countCities: citiesCount,
                      })
                    : t('Recommendations.campaign.published.geoTargetingCountryName', {
                        country: countryName,
                      })}
                </div>
              </div>
            )}
          </div>
        ))}
      </Layout>
    )
  }

  return null
}

Targeting.propTypes = {
  tc: PropTypes.string.isRequired,
  tc_run_id: PropTypes.number.isRequired,
  targeting: PropTypes.array,
}
