import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { components, components as reactSelectComponents } from 'react-select'

import QualifierDropdown from './QualifierDropdown'
import { useRangeLocations, useRangeLocationsDispatch } from './RangeLocationsContextProvider'
import { actions } from './rangeLocationsReducer'

import targetingApi from 'api/integration/targeting'
import { FdSelect, SearchDropdownIndicator } from 'components/Shared/ReactSelect'
import { SecondaryButton } from 'components/Shared/Button'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'

const LocationOption = (t) => (props) => {
  const {
    data: { value },
  } = props

  let leftText = value.name
  if (value.region && value.region !== value.name) {
    leftText += `, ${value.region}`
  }
  if (value.country_name) {
    leftText += `, ${value.country_name}`
  }
  const locationType = (value.geo_hierarchy_name ?? value.type)?.replace('_', ' ')?.toLowerCase()
  const rightText = t(
    `Recommendations.geoTargeting.rangeLocations.locationTypes.${locationType}`,
    locationType,
  )

  return (
    <reactSelectComponents.Option {...props}>
      <div className="range-locations_location-form__option">
        <span className="range-locations_location-form__option-left">{leftText}</span>
        <span className="range-locations_location-form__option-right">{rightText}</span>
      </div>
    </reactSelectComponents.Option>
  )
}

const NoOptionsMessage = ({ inputValue, searchError, ...props }) => {
  const { t } = useTranslation()

  if (!inputValue) {
    return null
  }

  if (!searchError) {
    return components.NoOptionsMessage(props)
  }

  return (
    <div className="range-locations_location-form__option range-locations_location-form__option--error">
      <div className="range-locations_location-form__option-left error-msg">
        {t('Recommendations.geoTargeting.rangeLocations.searchError')}
      </div>
      <SecondaryButton
        text={t('common.tryAgain')}
        classNames="range-locations_location-form__option-right"
        color
        // eslint-disable-next-line react/prop-types
        onClick={() => props.selectProps.onInputChange(props.selectProps.inputValue)}
        size="small"
      />
    </div>
  )
}

NoOptionsMessage.propTypes = {
  inputValue: PropTypes.string,
  searchError: PropTypes.bool,
}

export default function LocationForm() {
  const { t } = useTranslation()
  const [qualifier, setQualifier] = useState('include')
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState([])
  const dispatch = useRangeLocationsDispatch()
  const [selectKey, setSelectKey] = useState(0)
  const [searchError, setSearchError] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { locations } = useRangeLocations()

  const fetchOptions = async (inputValue) => {
    if (!inputValue) return
    setIsLoading(true)
    setSearchError(false)

    try {
      const response = await targetingApi.searchAsync(inputValue)
      if (response.status !== 200 || !response.data.results) {
        setSearchError(true)
      } else {
        const results = response.data.results
          .filter((item) => locations.every((location) => location.data.key !== item.key))
          .map((item) => ({
            value: item,
            label: item.name,
          }))
        setResults(results)
      }
    } catch (error) {
      setSearchError(true)
      console.error('Error fetching data: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), [locations])

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue)
    if (inputValue) {
      debouncedFetchOptions(inputValue)
    } else {
      setResults([])
    }
  }

  return (
    <div className="range-locations_location-form">
      <QualifierDropdown value={qualifier} onChange={(value) => setQualifier(value)} />
      <FdSelect
        key={selectKey} // reset the select when a location is added
        maxMenuHeight={200}
        isLoading={isLoading}
        options={results}
        onInputChange={handleInputChange}
        placeholder={t('Recommendations.geoTargeting.rangeLocations.searchLocation')}
        noOptionsMessage={() => t('Recommendations.geoTargeting.rangeLocations.noResults')}
        onChange={(option) => {
          setSelectKey(selectKey + 1)
          dispatch({
            type: actions.addLocationAsync,
            payload: { location: option.value, qualifier },
          })
        }}
        openMenuOnFocus={false}
        openMenuOnClick={false}
        components={{
          DropdownIndicator: SearchDropdownIndicator,
          Option: LocationOption(t),
          // eslint-disable-next-line react/display-name
          NoOptionsMessage: (props) => (
            <NoOptionsMessage {...props} inputValue={inputValue} searchError={searchError} />
          ),
          // eslint-disable-next-line react/display-name
          LoadingMessage: () => <LoadingSpinner className="py-3" size={SpinnerSize.SMALL} />,
          LoadingIndicator: () => null,
        }}
        filterOption={() => true} // don't filter options, we want to show all results
      />
    </div>
  )
}
