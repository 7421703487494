import { actions } from './actionTypes'
import { calculateSetupCreativesFromUpdatedTCCreatives } from './util'

export function reducer(state, action) {
  switch (action.type) {
    case actions.loading: {
      state.loading = action.payload ?? true
      return
    }
    case actions.error:
      state.loading = false
      state.error = action.payload
      return
    case actions.setupLoaded:
      state.loading = false
      state.setup = action.payload
      if (!action.payload) {
        state.lastSyncedAt = null
        state.isCreativesValid = null
        state.isTargetingValid = null
        state.isIntegrationsValid = null
        state.hasPendingMedia = null
        state.disabledVariations = {}
      }
      return
    case actions.setupSynced:
      state.loading = false
      state.lastSyncedAt = action.payload
      return
    case actions.setCreativesValid:
      state.isCreativesValid = action.payload
      return
    case actions.setTargetingValid:
      state.isTargetingValid = action.payload
      return
    case actions.setIntegrationsValid:
      state.isIntegrationsValid = action.payload
      return
    case actions.setConfig: {
      const {
        creatives = state.setup.creatives,
        goal = state.setup.goal,
        total_budget = state.setup.total_budget,
        start_date = state.setup.start_date,
        end_date = state.setup.end_date,
      } = action.payload
      state.setup.goal = goal
      state.setup.total_budget = total_budget
      state.setup.start_date = start_date
      state.setup.end_date = end_date
      state.setup.creatives = creatives
      return
    }
    case actions.setConversion:
      state.setup.conversion_id = action.payload
      return
    case actions.setTCCreatives: {
      const { tc, tc_run_id, hasPendingMedia = false, ...tcCreatives } = action.payload
      const { creatives } = state.setup
      const newCreatives = calculateSetupCreativesFromUpdatedTCCreatives(
        creatives,
        tc,
        tc_run_id,
        tcCreatives,
      )

      state.setup.creatives = newCreatives
      state.hasPendingMedia = hasPendingMedia
      return
    }
    case actions.setHasPendingMedia: {
      state.hasPendingMedia = action.payload
      return
    }
    case actions.setCreatives: {
      state.setup.creatives = action.payload
      return
    }
    case actions.setTargeting: {
      state.setup.targeting = action.payload
      return
    }
    case actions.setIntegrations:
      state.setup.integration_details = action.payload
      return
    case actions.setSetupDisabledVariations:
      state.disabledVariations = action.payload
      return
    case actions.setAccounts:
      state.accounts = action.payload
      state.loading = false
      return
    case actions.patchAccounts:
      state.accounts = state.accounts.map((account) => {
        const incomingAccount = action.payload.find((x) => x.id === account.id) || {}
        return { ...account, ...incomingAccount }
      })
      state.loading = false
      return
    default:
      return state
  }
}
