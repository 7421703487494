import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import CampaignBudget from 'components/Recommendations/CampaignBudget'
import CampaignGoals from 'components/Recommendations/CampaignGoals'
import TipDialogueBox from 'components/Shared/TipDialogueBox'
import { SecondaryButton } from 'components/Shared/Button'
import Fade from 'components/Shared/Wrappers/Fade'
import { hasRunningCampaigns, uniqueTasteClusterCount, getCampaignRuntime } from 'api/models'
import OpenCampaigns from './OpenCampaigns'
import {
  eventApi,
  fetchExchangeRates,
  fetchEventBudgetMinMax,
  fetchEventSuggestedTasteClusters,
} from 'api/events'
import { initStateObject, objectReducer } from 'reducers/default'
import CampaignRuntime from './CampaignRuntime'
import InfoBox from 'components/Shared/InfoBox'
import useIsFirstRender from 'hooks/useFirstRender'
import { useItcStatus } from 'components/Recommendations/Itc/ItcStatusContextProvider'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'
import {
  useCampaignSetupDispatch,
  useCampaignSetup,
  useCurrentStepDispatch,
} from 'components/Recommendations/Campaign/Context/CampaignSetupContext'
import useCustomTranslation from 'hooks/useCustomTranslation'
import { capitalizeString } from 'utils/helpers'
const calculateTotalRuntime = (startDate, endDate) => {
  return Math.round(new moment.duration(endDate - startDate).asDays())
}

const CampaignSetup = ({
  event,
  campaigns,
  suggestedTcs,
  selectedTcs,
  setSelectedTcs,
  initialBudget,
  initialGoal,
  dispatchSuggestedTcsAction,
  openCampaigns,
  refetchCampaigns,
  setSuggestedTcsChanged = () => {},
}) => {
  const { t } = useTranslation()
  const { setup } = useCampaignSetup()
  const isCampaignSetupInProgress = useMemo(() => !!setup, [setup])

  const { startSetup, reconfigureSetup } = useCampaignSetupDispatch()
  const { goToNextStep } = useCurrentStepDispatch()
  const isFirstRender = useIsFirstRender()
  const hasWaveAccess = useHasPermission(Permissions.wave)
  const [budgetRange, dispatchBudgetRangeAction] = useReducer(objectReducer, initStateObject)
  const [exchangeRate, dispatchExchangeRateAction] = useReducer(objectReducer, initStateObject)
  const budgetLoadingRef = React.useRef(false)
  const defaultIntegrationDetails = React.useRef({
    integration_details: [],
    conversion_id: '',
    custom_event_type: '',
  })
  const {
    mappedStatus: itcMappedStatus,
    loading: itcStatusLoading,
    error: itcStatusError,
  } = useItcStatus()
  const [eventBudget, setEventBudget] = useState(setup?.total_budget || initialBudget)
  const [eventGoal, setEventGoal] = useState(() => {
    if (!isCampaignSetupInProgress) return initialGoal

    const goalParts = setup?.goal.split('.')
    return goalParts[goalParts.length - 1]
  })
  const [isExchangeRateApplied, setIsExchangeRateApplied] = useState(false)
  const eventStartDate = moment(event.date)
  const showShortNoticeWarning = eventStartDate.diff(moment(), 'days') <= 7
  const totalUniqueTcs = uniqueTasteClusterCount(suggestedTcs.items)
  const [totalRuntime, setTotalRuntime] = useState(null)
  const baseCurrency = 'EUR'
  const userCurrency = localStorage.getItem('userCurrency') || ''
  const [dates, setDates] = useState({
    from: null,
    to: null,
  })
  const { getTranslation } = useCustomTranslation(event)

  useEffect(() => {
    if (exchangeRate.content?.exchange_rate) {
      setEventBudget((prevBudget) => Math.ceil(prevBudget * exchangeRate.content.exchange_rate))
      setIsExchangeRateApplied(true)
    }
  }, [exchangeRate.content.exchange_rate])

  useEffect(() => {
    if (typeof totalRuntime === 'number') {
      fetchEventBudgetMinMax(dispatchBudgetRangeAction, event.id, totalRuntime)
    }
  }, [event.id, totalRuntime])
  useEffect(() => {
    if (capitalizeString(userCurrency) !== 'EUR') {
      fetchExchangeRates(dispatchExchangeRateAction, baseCurrency, capitalizeString(userCurrency))
    }
  }, [baseCurrency, userCurrency])

  useEffect(() => {
    const shouldApplyExchangeRate = baseCurrency !== userCurrency
    if (!shouldApplyExchangeRate || isExchangeRateApplied) {
      fetchEventSuggestedTasteClusters(
        dispatchSuggestedTcsAction,
        event.id,
        eventBudget,
        eventGoal,
        capitalizeString(userCurrency),
      )
    }
  }, [
    event.id,
    eventBudget,
    eventGoal,
    dispatchSuggestedTcsAction,
    userCurrency,
    isExchangeRateApplied,
  ])

  useEffect(() => {
    if (!isFirstRender) setSuggestedTcsChanged(true)
  }, [event.id, eventBudget, eventGoal, setSuggestedTcsChanged, isFirstRender])

  useEffect(() => {
    const [runtimeStartDate, runtimeEndDate] = getCampaignRuntime(event)
    const todayDate = new Date()
    const startDate =
      setup?.start_date && new Date(setup.start_date) > todayDate
        ? new Date(setup.start_date)
        : runtimeStartDate.toDate()
    const endDate = setup?.end_date ? new Date(setup.end_date) : runtimeEndDate.toDate()

    setDates({
      from: startDate,
      to: endDate,
    })
    setTotalRuntime(calculateTotalRuntime(startDate, endDate))
  }, [setup, event])

  useEffect(() => {
    if (defaultIntegrationDetails.current.integration_details.length > 0) {
      return
    }

    eventApi.fetchEventDefaultCampaignParameters(event.id).then((response) => {
      if (response.status === 200 && response.data.integration_details?.length > 0) {
        defaultIntegrationDetails.current = {
          integration_details: response.data.integration_details,
          conversion_id: response.data.conversion_id,
          custom_event_type: response.data.custom_event_type,
        }
      }
    })
  }, [event.id])

  const updateDate = (date, dateRangeString) => {
    const formattedDate = date ? moment(date).toDate() : ''

    const currentDateTime = { ...dates }
    currentDateTime[dateRangeString] = formattedDate
    const runtimeDuration = calculateTotalRuntime(currentDateTime.from, currentDateTime.to)

    setDates(currentDateTime)
    setTotalRuntime(runtimeDuration)
  }

  const startOrContinueSetup = useCallback(() => {
    if (itcStatusError || itcStatusLoading || ['running', 'failed'].includes(itcMappedStatus)) {
      return
    }

    const selectedCampaigns = campaigns.items.filter((x) => selectedTcs.includes(x.tc))

    let config = {
      total_budget: eventBudget,
      goal: eventGoal,
      start_date: dates.from?.toISOString(),
      end_date: dates.to?.toISOString(),
      creatives: selectedCampaigns.map((x) => ({
        tc: x.tc,
        tc_run_id: x.tc_run_id,
      })),
    }

    if (!isCampaignSetupInProgress) {
      config = {
        ...config,
        integration_details: defaultIntegrationDetails.current.integration_details,
        conversion_id: defaultIntegrationDetails.current.conversion_id,
        custom_event_type: defaultIntegrationDetails.current.custom_event_type,
      }
    }

    if (isCampaignSetupInProgress) {
      reconfigureSetup(config, (result) => {
        if (result.success) {
          goToNextStep()
        }
      })
    } else {
      startSetup(config, (result) => {
        if (result.success) {
          goToNextStep()
        }
      })
    }
  }, [
    eventBudget,
    eventGoal,
    dates,
    selectedTcs,
    campaigns.items,
    goToNextStep,
    isCampaignSetupInProgress,
    itcMappedStatus,
    itcStatusError,
    itcStatusLoading,
    reconfigureSetup,
    startSetup,
  ])

  const eventHasRunningCampaigns = useMemo(
    () => hasRunningCampaigns(campaigns.items),
    [campaigns.items],
  )
  return (
    <div className="d-flex flex-column gap-5 p-0">
      <Fade transitionDuration={500}>
        <div className="d-flex flex-column">
          {showShortNoticeWarning && (
            <TipDialogueBox
              title={t('Recommendations.shortNoticeWarning.title')}
              text={getTranslation('Recommendations.shortNoticeWarning.content')}
              variant="warning-light"
            />
          )}

          <div className="d-flex flex-column flex-lg-row my-1 order-2 order-lg-1">
            <div className="flex-1 col-12 col-lg-5 px-0 mb-3 mb-lg-0">
              <CampaignGoals
                event={event}
                budget={eventBudget}
                eventId={event.id}
                onGoalSelected={setEventGoal}
                selectedGoal={eventGoal}
                disabled={eventHasRunningCampaigns}
              />
            </div>
            <div className="flex-1 col-12 col-lg-7 px-0 pl-lg-3">
              <CampaignBudget
                selectedBudget={eventBudget}
                maximumBudget={budgetRange.content.budget_upper_limit || 10000}
                defaultMinBudget={budgetRange.content.budget_lower_limit || 50}
                onChangeQueued={() => (budgetLoadingRef.current = true)}
                onBudgetChanged={(b) => {
                  setEventBudget(b)
                  budgetLoadingRef.current = false
                }}
                addressedTasteClusters={uniqueTasteClusterCount(suggestedTcs.items)}
                currency={campaigns.items?.length > 0 ? campaigns.items[0].currency : ''}
                exchangeRate={exchangeRate.content.exchange_rate}
              >
                <InfoBox
                  title={t('Tips.Recommendations.CampaignBudget.title')}
                  body={
                    <div>
                      <p>{getTranslation('Tips.Recommendations.CampaignBudget.body1')}</p>
                      <br />
                      <p>{t('Tips.Recommendations.CampaignBudget.body2')}</p>
                    </div>
                  }
                  placement="bottom"
                >
                  <h2 className="card-title-default d-inline-block event-header-title">
                    {t(
                      `Recommendations.campaignBudget.${eventHasRunningCampaigns ? 'selectBudgetForNewAudiences' : 'selectLabel'}`,
                    )}
                  </h2>
                </InfoBox>
              </CampaignBudget>
            </div>
          </div>
        </div>
      </Fade>
      <CampaignRuntime
        event={event}
        updateDate={updateDate}
        dates={dates}
        totalRuntime={totalRuntime}
      />
      <OpenCampaigns
        eventId={event.id}
        campaigns={openCampaigns}
        selectedTcs={selectedTcs}
        setSelectedTcs={setSelectedTcs}
        totalUniqueTcs={totalUniqueTcs}
        setupProcessId={isCampaignSetupInProgress ? setup.id : null}
        eventHasActiveCampaigns={eventHasRunningCampaigns}
        refetchCampaigns={refetchCampaigns}
      />
      <div className="recommendations-page_cta-btns_row order-1 order-lg-3">
        <SecondaryButton
          disabled={
            !selectedTcs.length ||
            !hasWaveAccess ||
            budgetLoadingRef.current === true ||
            itcStatusError ||
            itcStatusLoading ||
            ['running', 'failed'].includes(itcMappedStatus)
          }
          disabledTitle={!selectedTcs.length ? '' : null}
          className="bt"
          onClick={startOrContinueSetup}
          text={
            isCampaignSetupInProgress
              ? t('Recommendations.btnContinueSetup')
              : t('Recommendations.btnStartSetup')
          }
        />
      </div>
    </div>
  )
}

CampaignSetup.propTypes = {
  event: PropTypes.object,
  setup: PropTypes.object,
  campaigns: PropTypes.object,
  suggestedTcs: PropTypes.object,
  selectedTcs: PropTypes.arrayOf(Object),
  setSelectedTcs: PropTypes.func,
  initialBudget: PropTypes.number,
  initialGoal: PropTypes.string,
  dispatchSuggestedTcsAction: PropTypes.func,
  dispatchSetupAction: PropTypes.func,
  openCampaigns: PropTypes.arrayOf(Object),
  isCampaignSetupInProgress: PropTypes.bool,
  setSuggestedTcsChanged: PropTypes.func,
  refetchCampaigns: PropTypes.func.isRequired,
}

export default CampaignSetup
