import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes, { object } from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import moment from 'moment'

import StepCompletionBar from 'components/Recommendations/Campaign/StepCompletionBar'
import { Goals } from './Steps'
import { DEFAULT_CAMPAIGN_BUDGET, DEFAULT_CAMPAIGN_GOAL, isCampaignRunning } from 'api/models'
import { fetchEvent, fetchEventDefaultCampaignParameters } from 'api/events'
import SetupWrapper from './Steps/Setup/SetupWrapper'
import useIsFirstRender from 'hooks/useFirstRender'
import { useToast } from 'components/Shared/AppToast/ToastProvider'
import {
  useCampaignSetup,
  useCurrentStepDispatch,
  useCurrentStep,
} from '../Campaign/Context/CampaignSetupContext'
import { useCampaignSetupDispatch } from '../Campaign/Context/CampaignSetupContext'

const Step = (props) => {
  const firstRender = useIsFirstRender()
  const { setup } = useCampaignSetup()
  const { currentStep } = useCurrentStep()
  const { setCurrentStep } = useCurrentStepDispatch()

  useEffect(() => {
    if (!firstRender) return

    if (setup) setCurrentStep(2, 2)
    else setCurrentStep(1, 1)
  }, [firstRender, setup, setCurrentStep])

  switch (currentStep) {
    case 1:
      return <Goals {...props} />
    case 2:
      return <SetupWrapper {...props} child={'Accounts'} />
    case 3:
      return <SetupWrapper {...props} child={'Ads'} />
    case 4:
      return <SetupWrapper {...props} child={'Summary'} />
    default:
      return null
  }
}

Step.propTypes = {
  event: PropTypes.object,
  initialGoal: PropTypes.string,
  initialBudget: PropTypes.number,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  boost: PropTypes.func,
  isFutureEvent: PropTypes.bool,
}

const CampaignStepWrapper = ({ eventId, eventState, fetchedCampaigns, resetKey }) => {
  const { t } = useTranslation()
  const [eventGoal, setEventGoal] = useState(DEFAULT_CAMPAIGN_GOAL)
  const [eventBudget, setEventBudget] = useState(DEFAULT_CAMPAIGN_BUDGET)
  const [campaigns, setCampaigns] = useState([])
  // listens for breakpoints desktop => mobile && vice versa
  const [viewWidth, setViewWidth] = useState(window.innerWidth)
  const { addNotification } = useToast()

  const allCampaignsActive = useMemo(() => {
    return campaigns.every((campaign) => isCampaignRunning(campaign))
  }, [campaigns])

  const [event, dispatchEventAction] = eventState
  const { setup } = useCampaignSetup()
  const {
    fetchSetup,
    publishSetup,
    fetchAccounts,
    setError: setSetupError,
  } = useCampaignSetupDispatch()
  const { setCurrentStep, goToNextStep } = useCurrentStepDispatch()
  const { currentStep } = useCurrentStep()

  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  const isFutureEvent = useMemo(() => {
    return moment(event.content?.date) > moment()
  }, [event])

  useErrorHandler(event.error ? { userFriendlyMessage: event.message } : null)

  const handleResize = () => setViewWidth(window.innerWidth)

  useEffect(() => {
    // setup process was cancelled
    if (!setup && currentStep !== 1) {
      setCurrentStep(1, 1)
    }
  }, [setup, currentStep, setCurrentStep])

  useEffect(() => {
    if (!eventId) return

    const updateDefaultCampaignParameters = async (newDefaultCampaignParameters) => {
      if (!newDefaultCampaignParameters.error && newDefaultCampaignParameters.payload) {
        const goal = newDefaultCampaignParameters.payload.goal
        const budget = newDefaultCampaignParameters.payload.total_budget

        await setEventGoal(goal) // FIXME: Why??
        await setEventBudget(budget) // FIXME: Why??
      }
    }

    fetchEvent(dispatchEventAction, eventId)
    fetchEventDefaultCampaignParameters(updateDefaultCampaignParameters, eventId)
  }, [eventId, dispatchEventAction, resetKey])

  useEffect(() => {
    if (currentStep !== 5) return

    fetchEvent(dispatchEventAction, eventId)
    fetchSetup()
    fetchEventDefaultCampaignParameters((newDefaultCampaignParameters) => {
      if (!newDefaultCampaignParameters.error && newDefaultCampaignParameters.payload) {
        const goal = newDefaultCampaignParameters.payload.goal
        const budget = newDefaultCampaignParameters.payload.total_budget

        setEventGoal(goal)
        setEventBudget(budget)
      }
    }, eventId)

    const id = setTimeout(() => setCurrentStep(1), 2000)

    return () => clearTimeout(id)
  }, [eventId, dispatchEventAction, currentStep, setCurrentStep, fetchSetup, t])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onNext = () => {
    setCurrentStep(currentStep + 1, currentStep + 1)
  }

  const onBack = () => {
    setCurrentStep(currentStep - 1)
  }

  const boost = useCallback(() => {
    publishSetup(({ success, reason }) => {
      if (success) {
        addNotification({
          message: t('Toasts.Campaigns.campaignPublished.message'),
          description: t('Toasts.Campaigns.campaignPublished.description'),
        })
        goToNextStep()
      } else {
        if (reason === 'INVALID_SETUP') {
          setSetupError(t('Recommendations.campaign.setup.unknownError'))
          setCurrentStep(3, 2)
        } else if (reason === 'INVALID_ACCOUNTS') {
          setSetupError(
            <Trans
              i18nKey="Recommendations.campaign.setup.invalidAccounts"
              components={{
                a: (
                  <a
                    href="/settings#meta"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="d-inline-block"
                    style={{ color: '#2F80ED' }}
                  >
                    Meta assets page
                  </a>
                ),
              }}
            >
              Validation failed. Please reconnect the assets on{' '}
              <a href="/settings#meta">Meta asset page</a> and grant us the necessary access.
            </Trans>,
          )
          setCurrentStep(2, 1)
        }
      }
    })
  }, [publishSetup, goToNextStep, addNotification, setSetupError, setCurrentStep, t])

  // if mobile, redirect user to full screen views
  if (viewWidth < 991) {
    const urlParams = new URL(window.location.href)
    if (urlParams.searchParams.get('drawer-open') === 'true')
      return <Redirect to={`/campaigns/${eventId}`} />
  }

  if (!eventId) return null

  if (!event.success) return null

  if (currentStep === 5) {
    return <p className="alert alert-primary text-center mt-6">Your event has been boosted!</p>
  }

  return (
    <div>
      {isFutureEvent && !allCampaignsActive && <StepCompletionBar />}
      <Step
        event={event.content}
        initialGoal={eventGoal}
        initialBudget={eventBudget}
        onNext={onNext}
        onBack={onBack}
        boost={boost}
        setCampaigns={setCampaigns}
        isFutureEvent={isFutureEvent}
        fetchedCampaigns={fetchedCampaigns}
      />
    </div>
  )
}

CampaignStepWrapper.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventState: PropTypes.array.isRequired,
  resetKey: PropTypes.number,
  campaigns: PropTypes.arrayOf(object),
  setCampaigns: PropTypes.func,
  fetchedCampaigns: PropTypes.object,
}

export default CampaignStepWrapper
