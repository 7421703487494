import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { numberWithThousandsSeparators } from 'utils/helpers'
import QueryLine from './QueryLine'
import { ChevronIcon } from 'images'
import { useSentinelContext } from '../SentinelProvider'
import FDAccordion from 'components/Shared/FDAccordion'
import { TransparentBgButton } from 'components/Shared/Button'

export default function Query() {
  const { t } = useTranslation()
  const [activeKeys, setActiveKeys] = useState([])
  const [isRawSqlMode, setRawSqlMode] = useState(false)
  const {
    error,
    answer,
    results: { data: resultsData },
    loading,
  } = useSentinelContext()

  const num_results = answer?.num_results
  const formattedNumResults = useMemo(
    () => numberWithThousandsSeparators(num_results),
    [num_results],
  )

  if (error || !answer || (resultsData.length === 0 && loading)) {
    return null
  }
  const { filters, query, parameters } = answer

  return (
    <FDAccordion
      className={cn('sentinel_query', { 'sentinel_query--loading': loading })}
      defaultActiveKeys={activeKeys}
      onChange={(activePanels) => num_results > 0 && setActiveKeys(activePanels)}
      disabled={num_results === 0}
    >
      <FDAccordion.Item>
        <FDAccordion.Toggle as="div">
          <>
            <span type="button" className="sentinel_query__toggle">
              {t('Sentinel.query.title')}
            </span>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center justify-content-between gap-1 w-100 flex-wrap">
                <div>{t('Sentinel.query.summary')}</div>
                <span className="sentinel_query__summary__count">
                  {t('Sentinel.query.numberOfResults', { count: formattedNumResults })}
                </span>
              </div>
              {num_results > 0 && (
                <ChevronIcon
                  className={cn('sentinel_query__toggle__icon', {
                    'sentinel_query__toggle__icon--active': activeKeys.length > 0,
                  })}
                />
              )}
            </div>
          </>
        </FDAccordion.Toggle>
        <FDAccordion.Body>
          <div className="sentinel_query__section">
            <div className="sentinel_query__section__content">
              {isRawSqlMode ? (
                <code className="sentinel_query__raw-sql">{query}</code>
              ) : filters.length > 0 ? (
                filters.map((query, index) => (
                  <QueryLine key={index} query={query} parameters={parameters} />
                ))
              ) : (
                <p className="sentinel_query__section__empty-msg">{t('Sentinel.query.emptyMsg')}</p>
              )}
            </div>
            <TransparentBgButton
              text={isRawSqlMode ? t('Sentinel.query.mode.parsed') : t('Sentinel.query.mode.sql')}
              onClick={() => setRawSqlMode(!isRawSqlMode)}
              classNames="sentinel_query__section__mode"
              color="navy"
            />
          </div>
        </FDAccordion.Body>
      </FDAccordion.Item>
    </FDAccordion>
  )
}

Query.propTypes = {
  parameters: PropTypes.object,
  filters: PropTypes.shape({
    where: PropTypes.arrayOf(PropTypes.string),
    having: PropTypes.arrayOf(PropTypes.string),
  }),
}
