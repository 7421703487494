import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { MinusCircleIcon } from 'images'

import QualifierDropdown from './QualifierDropdown'
import { useRangeLocations, useRangeLocationsDispatch } from './RangeLocationsContextProvider'
import { actions, getPreferredRangeUnit } from './rangeLocationsReducer'
import RadiusInput from './RadiusInput'

function Location({ location }) {
  const {
    data: { key, name, region, type: locationType },
    qualifier,
    radius,
  } = location
  const dispatch = useRangeLocationsDispatch()

  const displayName = useMemo(
    () => (region && region !== name ? `${name}, ${region}` : name),
    [name, region],
  )

  const setQualifier = useCallback(
    (qualifier) => {
      dispatch({ type: actions.setQualifier, payload: { locationKey: key, qualifier } })
    },
    [key, dispatch],
  )

  const setRadius = useCallback(
    (radius) => {
      dispatch({
        type: actions.setLocationRadius,
        payload: { locationKey: key, radius: { value: radius, unit: getPreferredRangeUnit() } },
      })
    },
    [key, dispatch],
  )

  const removeLocation = useCallback(() => {
    dispatch({ type: actions.removeLocation, payload: { locationKey: key } })
  }, [key, dispatch])

  return (
    <div className="range-locations_summary__location">
      <div className="range-locations_summary__location__inner">
        <QualifierDropdown
          className="range-locations_summary__location__qualifier"
          value={qualifier}
          onChange={(value) => setQualifier(value)}
        />
        <span className="range-locations_summary__location-name">{displayName}</span>
        {locationType === 'city' && (
          <RadiusInput defaultValue={radius.value} unit={radius.unit} onChange={setRadius} />
        )}
      </div>
      <i
        title="remove"
        className="range-locations_summary__location-remove"
        onClick={removeLocation}
      >
        <MinusCircleIcon />
      </i>
    </div>
  )
}

Location.propTypes = {
  location: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      region: PropTypes.string,
      country_code: PropTypes.string,
    }),
    qualifier: PropTypes.string.isRequired,
    radius: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.oneOf(['miles', 'kilometers']),
    }),
  }),
}

function Country({ countryName, countryGroupName, locations }) {
  return (
    <div className="range-locations_summary__country">
      <h6 className="range-locations_summary__country__name">{countryGroupName || countryName}</h6>
      {locations.map((location) => (
        <Location key={location.data.key} location={location} />
      ))}
    </div>
  )
}
Country.propTypes = {
  countryName: PropTypes.string,
  countryGroupName: PropTypes.string,
  locations: PropTypes.array.isRequired,
}

export default function LocationsSummary() {
  const { locations } = useRangeLocations()
  const dispatch = useRangeLocationsDispatch()
  const { includedList, excludedList } = locations.reduce(
    (acc, location) => {
      const {
        data: {
          country_name: countryName,
          type,
          name: locationName,
          country_codes: regionCountriesList,
          key: locationKey,
          country_code: countryCodeData,
        },
        qualifier,
      } = location
      // Determine the type of location
      const isCountry = type === 'country' && countryName
      const isCountryGroup = type === 'country_group' && !countryName
      const isCity = type === 'city'
      const countryGroupName = isCountryGroup ? locationName : null
      const countryCode = isCountry ? locationKey : countryCodeData

      const includedList = acc.includedList
      const excludedList = acc.excludedList
      const list = qualifier === 'include' ? includedList : excludedList

      // Check if a country code exists in any country group within a given list
      const isCountryCodeInCountryGroup = (item, code) =>
        item.countryGroupName &&
        item.locations.some(
          (loc) => loc.data.country_codes && loc.data.country_codes.includes(code),
        )

      // Remove locations from the specified list based on a condition
      const removeLocations = (targetList, condition) => {
        for (let i = targetList.length - 1; i >= 0; i--) {
          const item = targetList[i]
          if (condition(item)) {
            dispatch({ type: actions.removeLocation, payload: { locationKey: item.locationKey } })
            targetList.splice(i, 1)
          }
        }
      }

      if (isCountryGroup) {
        removeLocations(
          includedList,
          (item) =>
            regionCountriesList?.includes(item.countryCode) && (item.isCountry || item.isCity),
        )

        // Remove matching countries, cities from excluded list
        removeLocations(
          excludedList,
          (item) =>
            regionCountriesList?.includes(item.countryCode) &&
            (item.isCountry || item.isCity) &&
            qualifier === 'exclude',
        )
      }

      if (isCountry) {
        // Remove matching country groups from included list
        removeLocations(
          includedList,
          (item) =>
            qualifier === 'include' &&
            isCountryCodeInCountryGroup(item, countryCode) &&
            item.isCountryGroup,
        )

        // Remove matching country groups from excluded list
        removeLocations(
          excludedList,
          (item) => isCountryCodeInCountryGroup(item, countryCode) && item.isCountryGroup,
        )

        // Remove cities within the country from included list
        removeLocations(includedList, (item) => item.countryCode === countryCode && item.isCity)

        // Remove cities within the country from excluded list
        removeLocations(
          excludedList,
          (item) => item.countryCode === countryCode && qualifier === 'exclude' && item.isCity,
        )
      }

      if (isCity) {
        // Remove matching countries from included list
        removeLocations(
          includedList,
          (item) => item.countryCode === countryCode && item.isCountry && qualifier === 'include',
        )

        // Remove matching countries from excluded list
        removeLocations(excludedList, (item) => item.countryCode === countryCode && item.isCountry)

        // Remove matching country groups from included list
        removeLocations(
          includedList,
          (item) =>
            qualifier === 'include' &&
            isCountryCodeInCountryGroup(item, countryCode) &&
            item.isCountryGroup,
        )

        // Remove matching country groups from included list
        removeLocations(
          excludedList,
          (item) => isCountryCodeInCountryGroup(item, countryCode) && item.isCountryGroup,
        )
      }

      list.push({
        countryName,
        countryGroupName,
        locations: [location],
        countryCode,
        locationKey,
        qualifier,
        isCity,
        isCountry,
        isCountryGroup,
      })

      return acc
    },
    { includedList: [], excludedList: [] },
  )

  const countries = [...includedList, ...excludedList]

  const { groupedCountries, groupedCountryGroups } = countries.reduce(
    (acc, item) => {
      if (item.isCountry || item.isCity) {
        const { countryName } = item
        const existingCountry = acc.groupedCountries.find(
          (country) => country.countryName === countryName,
        )

        if (existingCountry) {
          existingCountry.locations.push(...item.locations)
        } else {
          acc.groupedCountries.push({
            countryName,
            locations: [...item.locations],
          })
        }
      }

      if (item.isCountryGroup) {
        const { countryGroupName } = item
        const existingGroup = acc.groupedCountryGroups.find(
          (group) => group.countryGroupName === countryGroupName,
        )

        if (existingGroup) {
          existingGroup.locations.push(...item.locations)
        } else {
          acc.groupedCountryGroups.push({
            countryGroupName,
            locations: [...item.locations],
          })
        }
      }

      return acc
    },
    { groupedCountries: [], groupedCountryGroups: [] },
  )
  return (
    <div
      className={cn('range-locations_summary', {
        'range-locations_summary--empty': !locations.length,
      })}
    >
      {/* Render grouped countries */}
      {groupedCountries.map((country) => (
        <Country
          key={country.countryName}
          countryName={country.countryName}
          locations={country.locations}
        />
      ))}

      {/* Render grouped country groups */}
      {groupedCountryGroups.map((countryGroup) => (
        <Country
          key={countryGroup.countryGroupName}
          countryGroupName={countryGroup.countryGroupName}
          locations={countryGroup.locations}
        />
      ))}
    </div>
  )
}
