import { api, makeApiRequest } from './_init'
import { LOADING, UPDATE, FAIL, OVERWRITE, FAIL_EMPTY } from '../reducers/default'
import { parsePartnerIntegrations } from './models'
import { returnErrorMessage } from 'utils/helpers'

export const fetchEventFbInsights = async (dispatch, eventId) => {
  dispatch({ type: LOADING })

  if (eventId) {
    try {
      const result = await api.get(`/integrations/facebook/events/${eventId}/fb_insights`)

      dispatch({ type: UPDATE, payload: result.data })
    } catch (error) {
      dispatch({ type: FAIL_EMPTY })
    }
  }
}

export const fetchCampaignResults = async (dispatch, eventId) => {
  dispatch({ type: LOADING })

  if (eventId) {
    try {
      const result = await api.get(`/integrations/facebook/events/${eventId}/campaign_results`)

      dispatch({ type: UPDATE, payload: result.data })
    } catch (error) {
      dispatch({ type: FAIL_EMPTY, payload: returnErrorMessage({ ...error, displayTextOnly: true }) })
    }
  }
}

export const fetchAdPreviews = async (dispatch, eventId) => {
  dispatch({ type: LOADING })
  try {
    const result = await api.get(`/integrations/facebook/${eventId}/ads_previews`)
    let parsed = result.data.map((el, index) => {
      let template = document.createElement('template')
      el = el.trim()
      template.innerHTML = el
      let iframeEl = template.content.firstChild

      return {
        src: iframeEl.src,
        width: iframeEl.width,
        height: iframeEl.height,
        title: `meta-frame-${index}`,
      }
    })

    dispatch({ type: UPDATE, payload: parsed })
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const fetchPageInfos = async (dispatch, fbPageId) => {
  dispatch({ type: LOADING })

  try {
    const result = await api.get(`/integrations/facebook/page_infos?fb_page_id=${fbPageId}`)
    dispatch({ type: UPDATE, payload: result.data })
    return result.data
  } catch (error) {
    dispatch({ type: FAIL })
    return {
      error: true,
    }
  }
}

export const fetchConversionGoals = async (dispatch, adAccountId) => {
  dispatch({ type: LOADING })

  try {
    const result = await api.get(`/integrations/connect/facebook/assets/ad_accounts/${adAccountId}/conversions`)
    dispatch({ type: OVERWRITE, payload: result.data?.items })
  } catch (error) {
    dispatch({ type: FAIL, payload: returnErrorMessage({ ...error, displayTextOnly: true }) })
  }
}

export const fetchCustomConversionById = async (dispatch, adAccountId, conversionId) => {
  dispatch({ type: LOADING })

  try {
    const _conversionId = conversionId.split('.').pop()
    const result = await api.get(
      `/integrations/connect/facebook/assets/ad_accounts/${adAccountId}/conversions/${_conversionId}`,
    )
    dispatch({ type: OVERWRITE, payload: result.data })
  } catch (error) {
    dispatch({ type: FAIL, payload: returnErrorMessage({ ...error, displayTextOnly: true }) })
  }
}

export const fetchAudiences = async (dispatch, adAccountId, type) => {
  dispatch({ type: LOADING })

  try {
    const result = await api.get(
      `/integrations/connect/facebook/assets/ad_accounts/${adAccountId}/audiences?type=${type}`,
    )
    dispatch({ type: OVERWRITE, payload: result.data?.items })
  } catch (error) {
    dispatch({ type: FAIL, payload: returnErrorMessage({ ...error, displayTextOnly: true }) })
  }
}

const integrationsApi = {
  fetchAccountsAsync: async () => {
    const response =  await makeApiRequest(api, 'get', '/partner_integrations')
    if (!response.error) {
      return { data: parsePartnerIntegrations(response.data) }
    }

    return response
  }
}

export default integrationsApi
