import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useReducer, useState, useRef } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { throttle } from 'lodash'
import classnames from 'classnames'
import { ErrorBoundary } from 'react-error-boundary'
import axios from 'axios'

import Layout from '../components/Layout'
import {
  DEFAULT_EVENT_FILTERS,
  EventFilterContext,
  EventFilterDispatchContext,
} from '../components/Events/Filters'

import {
  initStateArray,
  arrayReducer,
  initStateObject,
  objectReducer,
  STALE,
  OVERWRITE,
} from '../reducers/default'
import { fetchEvent, fetchEvents } from '../api/events'
import SideDrawer from '../components/Shared/SideDrawer'
import LoadingSpinner, { SpinnerSize } from '../components/Shared/LoadingSpinner'
import EventList from '../components/Event/List/EventList'
import EventRow from '../components/Event/List/EventRow'

import ErrorFallback from '../components/Shared/ErrorFallback'
import ErrorMessage from '../components/Shared/ErrorMessage'
import { getEventFetchFilters } from '../api/models'
import EventControlPanel from 'components/Events/EventControlPanel'
import Recommendation from 'components/Recommendations/Campaign/Recommendation'
import { CampaignMenuContextProvider } from 'components/Recommendations/Campaign/CampaignMenu/CampaignMenuContext'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

const pageInit = { number: 1, fetched: 1, loading: false }

/**
 * Recommendations - page template.
 * It displays tabs with active, open, and ignored events in the left sidebar and chosen event campaigns in the right column.
 * @Tags( page, layout, recommendations, campaigns, logged-users, api )
 * @SuggestedTags( recommendations )
 * @Endpoints( /events/ )
 * @ApiLogic( ../api/events )
 * @Routes( /login )
 */
const RecommendationsContent = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState({ ...pageInit })
  const [events, dispatch] = useReducer(arrayReducer, initStateArray)
  const [selectedEvent, eventDispatch] = useReducer(objectReducer, initStateObject)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const filters = useContext(EventFilterContext)
  const dispatchFiltersAction = useContext(EventFilterDispatchContext)
  const hasLookoutAccess = useHasPermission(Permissions.lookout)
  const hasWaveAccess = useHasPermission(Permissions.wave)
  const cancelTokenSource = useRef(null)

  useEffect(() => {
    if (!drawerOpen) {
      eventDispatch({ type: STALE })
      if (!selectedEvent.loading) eventDispatch({ type: OVERWRITE, payload: initStateObject })

      return
    }

    const urlParams = new URL(window.location.href)
    const eventId = urlParams.searchParams.get('event-id')
    if (selectedEvent.content.id === eventId) return

    eventId && fetchEvent(eventDispatch, eventId)
  }, [drawerOpen, selectedEvent.loading, selectedEvent.content.id])

  const loadNextPage = useCallback(() => {
    if (page.loading) return

    const pageNumber = page.number + 1
    setPage({ number: pageNumber, loading: true })

    fetchEvents(
      (action) => {
        dispatch(action)
        setPage((page) => ({ ...page, loading: false }))
      },
      getEventFetchFilters(filters, pageNumber),
    )
  }, [page, filters])

  useEffect(() => {
    const handleScroll = () => {
      if (
        events.maxPage > page.number &&
        !page.loading &&
        document.body.scrollHeight - (window.innerHeight + window.scrollY) < 300
      ) {
        loadNextPage()
      }
    }
    const throttledScroll = throttle(handleScroll, 1000)

    window.addEventListener('scroll', throttledScroll)
    return () => window.removeEventListener('scroll', throttledScroll)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, page])

  const filterEvents = useCallback((eventFilters) => {
    if (!eventFilters) return
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('Request canceled due to a new filter.')
    }
    cancelTokenSource.current = axios.CancelToken.source()

    setPage({ ...pageInit })
    // Fetch events with the new filters and CancelToken
    fetchEvents(dispatch, {
      ...getEventFetchFilters(eventFilters),
      cancelToken: cancelTokenSource.current.token,
    })
    dispatchFiltersAction({ type: 'SET_OPEN', payload: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetFilters = (includeAll = true) => {
    if (includeAll) {
      dispatchFiltersAction({ type: 'CLEAR_ALL' })
      filterEvents({ ...DEFAULT_EVENT_FILTERS })
    } else {
      dispatchFiltersAction({ type: 'CLEAR_ALL_BUT_PAST' })
      filterEvents({ ...DEFAULT_EVENT_FILTERS, includePastEvents: filters?.includePastEvents })
    }
  }

  useEffect(() => {
    filterEvents(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout
      loggedIn
      title={t('Recommendations.PageTitle')}
      mobileTopBar={{ centerTitle: t('Recommendations.MobileTitle') }}
    >
      <Helmet bodyAttributes={{ class: 'events-page' }} />
      <main>
        <div
          data-testid="campaigns-page-wrapper"
          style={{ minHeight: '100vh', paddingBottom: '8em' }}
          className="container-fluid hide-scroll-bar"
        >
          <div className="col-12 pl-0">
            <h1 className="main-title hide-mobile">{t('Recommendations.MainTitle')}</h1>
          </div>
          <div className="mt-4">
            <EventControlPanel
              filters={filters}
              onDispatch={dispatchFiltersAction}
              onSubmit={filterEvents}
              onReset={(includeAll) => resetFilters(includeAll)}
              disabled={events.loading}
            />
          </div>
          <div
            className={classnames({
              'events-page-search-filter_table card mt-3': true,
              'events-page-search-filter_table--filtering': filters.open,
            })}
            data-testid="events-search-table"
          >
            {drawerOpen &&
              selectedEvent.content.id &&
              events.items.every((x) => x.id !== selectedEvent.content.id) && (
                <EventRow
                  event={selectedEvent.content}
                  key={selectedEvent.content.id}
                  isSelected
                  isDisabled={!hasLookoutAccess}
                  classNames="hover"
                />
              )}
            <div
              data-testid="events-search-table-results"
              className="events-page-search-filter_results_container"
            >
              {events.items.length > 0 ? (
                <EventList
                  events={events.items}
                  rowRender={(event) => (
                    <EventRow
                      event={event}
                      key={event.id}
                      onClick={(eventId) => {
                        eventId && fetchEvent(eventDispatch, eventId)
                      }}
                      shouldScrollOnLoad
                      isSelected={selectedEvent.content.id === event.id}
                      isDisabled={!hasWaveAccess}
                      classNames="hover"
                    />
                  )}
                />
              ) : null}
            </div>
            {!events.loading && !events.items.length > 0 ? (
              <p
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
              >
                {t('Events.results.empty')}
              </p>
            ) : null}
          </div>
          {events.loading ? (
            <LoadingSpinner portalBackground={false} size={SpinnerSize.LARGE} />
          ) : null}
          <SideDrawer drawerOpen={drawerOpen} toggle={setDrawerOpen} classes="no-padding">
            {selectedEvent.error && (
              <ErrorMessage classNames="mt-6" danger>
                {t(selectedEvent.message)}
              </ErrorMessage>
            )}
            {selectedEvent.success && selectedEvent.content.id && !selectedEvent.stale && (
              <CampaignMenuContextProvider event={selectedEvent.content}>
                <Recommendation event={selectedEvent.content} />
              </CampaignMenuContextProvider>
            )}
          </SideDrawer>
        </div>
      </main>
    </Layout>
  )
}

RecommendationsContent.propTypes = {
  /**
   * Link match with optional event ID
   */
  match: PropTypes.object,
}

export default function Recommendations() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <RecommendationsContent />
    </ErrorBoundary>
  )
}

// Recommendations.whyDidYouRender = true
