import axios from 'axios'
import * as Sentry from '@sentry/react'
import constants from '../constants'
import { getAuthorizationHeader } from './_token'
import store from '../store'
import { LOGOUT } from '../reducers/user'
import { joinPaths } from 'utils/helpers'

export const apiURL = constants.API_URL
export const apiVersion = constants.API_VERSION
export const crURL = constants.CR_URL
export const appTimeout = parseInt(constants.APP_TIMEOUT, 10)

const baseURL = joinPaths(apiURL, apiVersion)
export const api = axios.create({
  baseURL,
  timeout: appTimeout,
  transformRequest: [
    function (data, headers) {
      const authorization = getAuthorizationHeader()
      const partnerId = store.getState()?.user?.partnerId

      if (authorization) {
        headers['Authorization'] = authorization
      }

      if (partnerId) {
        headers['X-Preferred-Partner-Id'] = partnerId
      }
      return JSON.stringify(data)
    },
  ],
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.response.use(null, (error) => {
  if (error && error.response && error.response.status === 401) {
    store.dispatch({ type: LOGOUT })
  }
  return Promise.reject(error)
})

export const cr_api = axios.create({
  baseURL: crURL,
  timeout: appTimeout,
  crossdomain: true,
  transformRequest: [
    function (data, headers) {
      const authorization = getAuthorizationHeader()
      const partnerId = store.getState()?.user?.partnerId

      if (authorization) {
        headers['Authorization'] = authorization
      }
      if (partnerId) {
        headers['X-Preferred-Partner-Id'] = partnerId
      }
      return JSON.stringify(data)
    },
  ],
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

export async function makeApiRequest(
  targetApi = api,
  method,
  endpoint,
  requestData,
  { reportError = false, signal = null, cancelToken = null } = {},
) {
  try {
    const headers = {}
    const partnerSession = JSON.parse(sessionStorage.getItem('partner-session') || '{}')
    if ('access_token' in partnerSession) {
      headers['X-Partner-Token'] = partnerSession['access_token']
    } else {
      const anonymousSession = JSON.parse(sessionStorage.getItem('anonymous-session') || '{}')
      if ('access_token' in anonymousSession) {
        headers['X-Auth-Code'] = anonymousSession['access_token']
      }
    }

    const response =
      method === 'delete'
        ? await targetApi.delete(endpoint, { data: requestData, headers, signal })
        : method === 'get'
          ? await targetApi.get(endpoint, { headers, cancelToken })
          : await targetApi[method](endpoint, requestData, { headers, cancelToken })
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.data.message)
    }
    if (process.env.NODE_ENV === 'development') {
      console.log(`[${method.toUpperCase()}] ${endpoint}`, response.data)
    }

    return {
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
      return { error: error.message, status: null }
    } else {
      console.error(error)
      if (reportError) Sentry.captureException(error)
      return { error, status: error.response?.status }
    }
  }
}
