import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

export default function Asset({ asset, username, onToggle, disabled = false }) {
  const { t } = useTranslation()
  return (
    <div className="settings-page__sectionBody">
      <div className="settings-page__sectionWrapper">
        <div className="settings-page__sectionState position-relative">
          <div className="d-flex align-items-center gap-2">
            <label className="custom-check notification-setting custom-check--navy">
              <input
                className="custom-check__input"
                id={asset.asset_id}
                type="checkbox"
                checked={asset.is_enabled}
                onChange={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onToggle(asset.id, !asset.is_enabled)
                }}
                disabled={disabled}
              />
              <span className="custom-check__box"></span>
            </label>
            <span>
              {asset.asset_label} (ID: {asset.asset_id})
            </span>
            <span
              className={cn('asset__active-label', {
                'asset__active-label--active': asset.is_active,
              })}
            >
              {asset.is_active ? t('common.active') : t('common.inactive')}
            </span>
          </div>
        </div>
        {username && <div className="settings-page__sectionColumn">
          {t('Settings.MetaAccess.Assets.connectedBy')}: {username}
        </div>}
      </div>
    </div>
  )
}

Asset.propTypes = {
  asset: PropTypes.object.isRequired,
  username: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
