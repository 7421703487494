import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import FDAccordion from 'components/Shared/FDAccordion'
import CopyToClipboard from 'components/Shared/CopyToClipeboard'

export default function CustomerProfile({ customerDetails }) {
  const { t } = useTranslation()
  const address = useMemo(
    () => `${customerDetails.street}, ${customerDetails.zip_code}, ${customerDetails.city}`,
    [customerDetails],
  )
  const [activeKeys, setActiveKeys] = useState([0])

  return (
    <div className={cn('customer-details__profile', { open: activeKeys.length > 0 })}>
      <FDAccordion defaultActiveKeys={activeKeys} onChange={setActiveKeys}>
        <FDAccordion.Item key={0}>
          <FDAccordion.Toggle>
            <div className="d-flex justify-content-between align-items-center">
              <div>{t('Sentinel.results.customerDetails.title')}</div>
              <div className={cn('accordion-arrow', { open: activeKeys.length > 0 })} />
            </div>
          </FDAccordion.Toggle>
          <FDAccordion.Body>
            <div className="customer-details__profile__content">
              <div className="customer-details__row">
                <h6>{t('Sentinel.results.customerDetails.customerId')}</h6>
                <CopyToClipboard
                  className="customer-details__profile__value"
                  text={customerDetails.id}
                >
                  <p className="customer-details__profile__value">{customerDetails.id}</p>
                </CopyToClipboard>
              </div>
              {address && (
                <div className="customer-details__row">
                  <h6 className="customer-details__profile__title">
                    {t('Sentinel.results.customerDetails.address')}
                  </h6>
                  <CopyToClipboard text={address}>
                    <p className="customer-details__profile__value">{address}</p>
                  </CopyToClipboard>
                </div>
              )}
              {(customerDetails.email || customerDetails.email2) && (
                <div className="customer-details__row">
                  <h6 className="customer-details__profile__title">
                    {t('Sentinel.results.customerDetails.email')}
                  </h6>
                  <div className="d-flex flex-column gap-1">
                    <CopyToClipboard text={customerDetails.email}>
                      <a
                        className="customer-details__profile__value"
                        href={`mailto:${customerDetails.email}`}
                      >
                        {customerDetails.email}
                      </a>
                    </CopyToClipboard>
                    <CopyToClipboard text={customerDetails.email2}>
                      <a
                        className="customer-details__profile__value"
                        href={`mailto:${customerDetails.email2}`}
                      >
                        {customerDetails.email2}
                      </a>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
              {(customerDetails.phone1 || customerDetails.phone2) && (
                <div className="customer-details__row">
                  <h6 className="customer-details__profile__title">
                    {t('Sentinel.results.customerDetails.phone')}
                  </h6>
                  <div className="d-flex flex-column gap-1">
                    <CopyToClipboard text={customerDetails.phone1}>
                      <span className="customer-details__profile__value">
                        {customerDetails.phone1}
                      </span>
                    </CopyToClipboard>
                    <CopyToClipboard text={customerDetails.phone2}>
                      <span className="customer-details__profile__value">
                        {customerDetails.phone2}
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
              <div className="customer-details__row">
                <h6 className="customer-details__profile__title">
                  {t('Sentinel.results.customerDetails.adsOptOut')}
                </h6>
                <p>{customerDetails.ads_opt_out ? t('common.true') : t('common.false')}</p>
              </div>
            </div>
          </FDAccordion.Body>
        </FDAccordion.Item>
      </FDAccordion>
    </div>
  )
}

CustomerProfile.propTypes = {
  customerDetails: PropTypes.shape({
    id: PropTypes.string,
    street: PropTypes.string,
    zip_code: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    email2: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    ads_opt_out: PropTypes.bool,
  }),
}
