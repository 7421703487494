import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'

import DropDown from 'components/Shared/DropDown'
import { SecondaryButton } from 'components/Shared/Button'
import { StarFilledIcon, StarIcon } from 'images'
import InfoBox from 'components/Shared/InfoBox'
import { useSentinelContext, useSentinelDispatchContext } from './SentinelProvider'
import moment from 'moment'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import { numberWithThousandsSeparators } from 'utils/helpers'
const MAX_CHAR_LIMIT = 5000
const WARNING_LIMIT = 4500
const SAFE_LIMIT = 4000

const outcomeOptions = [
  { text: 'Sentinel.input.outcome.customer', value: 'customers' },
  { text: 'Sentinel.input.outcome.transaction', value: 'transactions' },
  { text: 'Sentinel.input.outcome.event', value: 'events' },
]

/**
 * A natural language input
 */
export default function SentinelInput({ createCancelToken, onCancelRequest }) {
  const { t } = useTranslation()
  const {
    question,
    outcome,
    answer,
    results: { data: resultsData, localQuestionId: resultId },
    loading,
    savedQuestions: { activeQuestionId },
    localQuestionId,
  } = useSentinelContext()
  const { setOutcome, setQuestion, saveQuestion, textToNLQ, askAgain } =
    useSentinelDispatchContext()
  const [canSave, setCanSave] = useState(false)

  const alreadyAnswered = useMemo(
    () => !!localQuestionId && localQuestionId === resultId,
    [localQuestionId, resultId],
  )

  const queryName = t('Sentinel.input.defaultQuestionName', {
    date: moment().format('DD.MM.YYYY HH:mm'),
  })

  const sortedOutcomeOptions = outcomeOptions.sort((a, b) => {
    const textA = t(a.text)
    const textB = t(b.text)
    return textA.localeCompare(textB)
  })

  useEffect(() => {
    setCanSave(
      resultsData.length > 0 &&
        !loading &&
        // Question changed, disable save while until an answer is available.
        !!localQuestionId,
    )
  }, [resultsData.length, loading, localQuestionId])

  const onAskClick = useCallback(() => {
    const cancelToken = createCancelToken()
    if (alreadyAnswered) {
      askAgain(answer.query_id, cancelToken)
    } else {
      textToNLQ(cancelToken)
    }
  }, [alreadyAnswered, askAgain, textToNLQ, answer, createCancelToken])

  const onStopClick = useCallback(() => {
    onCancelRequest()
  }, [onCancelRequest])

  const handleQuestionChange = (e) => {
    const input = e.target.value
    if (input.length <= MAX_CHAR_LIMIT) {
      setQuestion(input)
    }
  }

  const charCountStyle = useMemo(() => {
    if (!question) return { color: 'grey' }
    if (question.length <= SAFE_LIMIT) return { color: 'green' }
    if (question.length <= WARNING_LIMIT) return { color: 'orange' }
    return { color: 'red' }
  }, [question])

  const formattedCharCount = useMemo(() => {
    const currentCount = question?.length || 0
    return numberWithThousandsSeparators(currentCount)
  }, [question])

  const formattedMaxCount = useMemo(() => numberWithThousandsSeparators(MAX_CHAR_LIMIT), [])

  return (
    <Card className="sentinel_input__container">
      {!question && (
        <div className="sentinel_input__placeholder">
          <p className="placeholder">{t('Sentinel.input.inputPlaceholder.placeholder')}</p>
          <p className="description">{t('Sentinel.input.inputPlaceholder.description1')}</p>
          <p className="description">{t('Sentinel.input.inputPlaceholder.description2')}</p>
          <p className="description">{t('Sentinel.input.inputPlaceholder.description3')}</p>
          <p className="description">{t('Sentinel.input.inputPlaceholder.description4')}</p>
          <p className="tip">{t('Sentinel.input.inputPlaceholder.tip')}</p>
        </div>
      )}
      <textarea onChange={handleQuestionChange} value={question} maxLength={MAX_CHAR_LIMIT} />
      <div style={{ textAlign: 'right', fontSize: '16px', marginTop: '16px' }}>
        <span style={charCountStyle}>
          {formattedCharCount}/{formattedMaxCount}
        </span>
      </div>
      <hr className="divider" />
      <div className="sentinel_input__options">
        <DropDown
          classNames="sentinel_input__options__outcomes"
          options={sortedOutcomeOptions}
          placeholder={t('Sentinel.input.selectOutcome')}
          required={false}
          onChange={setOutcome}
          value={outcome}
          disabled={loading}
        />
        <InfoBox content="Tips.Sentinel.Ask">
          {loading ? (
            <SecondaryButton
              classNames="sentinel_input__options__ask"
              color="orange"
              text={t('Sentinel.input.stop')}
              onClick={onStopClick}
            />
          ) : (
            <SecondaryButton
              classNames="sentinel_input__options__ask"
              color="orange"
              text={t(`Sentinel.input.${alreadyAnswered ? 'askAgain' : 'ask'}`)}
              disabled={!question || !outcome || loading}
              onClick={onAskClick}
            />
          )}
        </InfoBox>
        {loading && (
          <LoadingSpinner size={SpinnerSize.SMALL} title={t('Sentinel.input.loadingMsg')} />
        )}
        <InfoBox
          title={
            activeQuestionId
              ? t('Sentinel.savedQuestions.title')
              : t('Sentinel.input.saveThisQuestion')
          }
          showBody={false}
          withAnchor={false}
          classNames="sentinel_input__options__star-container"
          titleComponent="h6"
          enabled={canSave}
        >
          <button
            className={cn('bt sentinel_input__options__star', {
              'sentinel_input__options__star--saved': !!activeQuestionId,
              'sentinel_input__options__star--disabled': !canSave,
            })}
            onClick={() => {
              if (!canSave || activeQuestionId) return

              saveQuestion(answer.query_id, queryName)
            }}
          >
            {activeQuestionId ? (
              <StarFilledIcon width={20} height={20} />
            ) : (
              <StarIcon width={20} height={20} />
            )}
          </button>
        </InfoBox>
      </div>
    </Card>
  )
}
SentinelInput.propTypes = {
  createCancelToken: PropTypes.func.isRequired,
  onCancelRequest: PropTypes.func.isRequired,
}
