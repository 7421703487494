import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { humanizeParameterName, humanizeSQLOperator } from './utils'
import { useTranslation } from 'react-i18next'
import { localizeDateObj } from 'utils/helpers'

export default function QueryLine({ query, parameters }) {
  const { t } = useTranslation()
  const { parameter, operator, value } = queryLineToParts(query, parameters)

  return (
    <div className="sentinel_query__line">
      <div className="sentinel_query__item name">{t(parameter)}</div>
      <div className="sentinel_query__item operator">
        <span>{operator}</span>
      </div>
      <div className="sentinel_query__item value">
        <QueryValue value={value} />
      </div>
    </div>
  )
}

QueryLine.propTypes = {
  query: PropTypes.string,
  parameters: PropTypes.object,
}

function QueryValue({ value }) {
  const isDate = useMemo(
    () => typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T/),
    [value],
  )

  if (!value) {
    return <span>NULL</span>
  }

  return (
    <>
      {isDate && <span>{localizeDateObj(new Date(value))}</span>}
      {typeof value === 'string' && value.length > 100 && <span>{value.substring(0, 100)}...</span>}
      {typeof value === 'string' && !(isDate || value.length > 100) && <span>{value}</span>}
      {typeof value === 'object' && <span>Do not know how to render this!</span>}
      {typeof value !== 'string' && typeof value !== 'object' && <span>{value}</span>}
    </>
  )
}

QueryValue.propTypes = {
  value: PropTypes.any,
}

function queryLineToParts(queryLine, parameters) {
  const parts = splitQueryLine(queryLine)

  if ('COUNT(' === queryLine.substring(0, 6).toUpperCase()) {
    return havingLineToParts(parts, parameters)
  }

  const parameterPlaceholder = parts[2].trim()
  const parameterName = parts[0].replace(/^.*\./gi, '').trim()
  return {
    parameter: humanizeParameterName(renderLeftSide(parameterName, parameters)),
    operator: humanizeSQLOperator(parts[1]),
    value: renderRightSide(parameterPlaceholder, parameters),
  }
}

function havingLineToParts(parts, parameters) {
  const parameterPlaceholder = parts[2].trim()

  return {
    parameter: 'Sentinel.query.groupCount',
    operator: humanizeSQLOperator(parts[1]),
    value: renderRightSide(parameterPlaceholder, parameters),
  }
}

function splitQueryLine(queryLine) {
  const supportedOperators = [
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'LIKE',
    'ILIKE',
    'IN',
    'NOT IN',
    'IS',
    'IS NOT',
  ]

  const operator = supportedOperators.find((op) => queryLine.match(new RegExp(`\\s${op}\\s`)))
  const operatorIndex = queryLine.indexOf(operator)
  const operatorLength = operator.length
  const parts = [
    queryLine.substring(0, operatorIndex).trim(),
    operator,
    queryLine.substring(operatorIndex + operatorLength).trim(),
  ]

  return parts
}

function renderRightSide(parameterPlaceholder, parameters) {
  return parameterPlaceholder.replace(/:\w+/g, (match) => {
    const paramName = match.replace(':', '')
    const value = parameters[paramName]
    return typeof value === 'string' ? value.replace(/^%|%$/g, '') : value
  })
}

function renderLeftSide(parameterPlaceholder, parameters) {
  return parameterPlaceholder.replace(/:\w+/g, (match) => {
    const paramName = match.slice(1)
    const value = parameters[paramName]

    // Handle special case for 'artist_name'
    if (value === 'artist_name') {
      return 'artist'
    }
    return value
  })
}
