
export const actions = Object.freeze({
  loading: 'LOADING',
  error: 'ERROR',
  setupLoaded: 'SETUP_LOADED',
  setupSynced: 'SETUP_UPDATED',
  setConfig: 'SET_CONFIG',
  setConversion: 'SET_CONVERSION',
  setTCCreatives: 'SET_TC_CREATIVES',
  setCreatives: 'SET_CREATIVES',
  setTargeting: 'SET_TARGETING',
  setIntegrations: 'SET_INTEGRATIONS',
  setCreativesValid: 'SET_CREATIVES_VALID',
  setTargetingValid: 'SET_TARGETING_VALID',
  setIntegrationsValid: 'SET_INTEGRATIONS_VALID',
  setSetupDisabledVariations: 'SET_SETUP_DISABLED_VARIATIONS',
  setAccounts: 'SET_ACCOUNTS',
  patchAccounts: 'PATCH_ACCOUNTS',

  fetchAsync: 'FETCH_ASYNC',
  syncAsync: 'SYNC_ASYNC',
  createAsync: 'CREATE_ASYNC',
  reconfigureAsync: 'RECONFIGURE_ASYNC',
  deleteAsync: 'DELETE_ASYNC',
  publicAsync: 'PUBLISH_ASYNC',
  editAsync: 'EDIT_ASYNC',
  updateTCTargetingAsync: 'UPDATE_TC_TARGETING_ASYNC',
  fetchAccountsAsync: 'FETCH_ACCOUNTS_ASYNC',
  validateAccountsAsync: 'VALIDATE_ACCOUNTS_ASYNC',
  validateAccountsAndReconfigureAsync: 'VALIDATE_AND_RECONFIGURE_ASYNC',
})
