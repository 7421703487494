import { api, apiURL, makeApiRequest } from './_init'
import qs from 'qs'
import store from 'store'
import axios from 'axios'
import { getAuthorizationHeader } from './_token'
import constants from '../constants'
import i18n from '../i18n'

const nlqApi = {
  textToNLQ: async (text, resultType, cancelToken) => {
    const params = new URLSearchParams()
    params.append('description', text)
    params.append('result_type', resultType)
    params.append('language', i18n.language)
    return await makeApiRequest(
      api,
      'get',
      `/nlq/ids_from_nl_description?${params.toString()}`,
      null,
      { cancelToken },
    )
  },
  askAgainAsync: async (queryId, cancelToken) => {
    return await makeApiRequest(
      api,
      'post',
      `nlq/ids_from_nl_description?nlq_id=${queryId}`,
      null,
      { cancelToken },
    )
  },
  dataFromNLQ: async (queryId, ids, page = 1, pageSize = 50) => {
    const params = new URLSearchParams()
    params.append('nlq_id', queryId)

    const start = (page - 1) * pageSize
    const end = start + pageSize
    ids.slice(start, end).forEach((id) => params.append('id', id))

    return await makeApiRequest(api, 'get', `/nlq/data_from_ids?${params.toString()}`)
  },
  saveQuestion: async (queryId, name, save = true) => {
    const params = new URLSearchParams()
    params.append('name', name)
    params.append('save', save.toString())

    return await makeApiRequest(api, 'put', `/nlq/${queryId}?${params.toString()}`)
  },
  fetchSavedQuestions: async () => {
    return await makeApiRequest(api, 'get', '/nlq')
  },

  exportToCsvOrExcel: async (queryId, ids, fileType) => {
    const url = `${apiURL}${constants.API_VERSION}/nlq/export_file_from_ids`
    const partnerId = store.getState()?.user?.partnerId
    const result = await axios.post(
      url,
      {
        ids,
        nlq_id: Number(queryId),
        file_type: fileType,
      },
      {
        headers: {
          Authorization: getAuthorizationHeader(),
          'X-Preferred-Partner-Id': partnerId,
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        responseType: 'blob',
      },
    )

    return {
      success: true,
      data: result.data,
    }
  },
}

export default nlqApi
